import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { OAuthService } from 'angular-oauth2-oidc';
import { PermissionsService } from '../services/permissions/permissions.service';
import { ModulePermissions } from '../models/appModules.permissions';
import { map } from 'rxjs/operators';
import { TenantModulesPermissionModel } from '../models/tenantModel';
import { TenantService } from '../services/tenant/tenant.service';
import { Constants } from '../shared/shared.constants';
import { Permission } from '../vos/permission/permission';
import { SynapzeCxOAuthserviceService } from './synapze-cx-oauthservice.service';
import { CORE_SESSION_STORAGE } from '../services/storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private modulePermissions: TenantModulesPermissionModel[] = [];
  private userPermissions: any;
  constructor(private oauthService: SynapzeCxOAuthserviceService,
    private permissionsService: PermissionsService,
    private tenantService: TenantService,
    private router: Router,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    this.tenantService.getTenantModules().subscribe(result => {
      result = result.filter(x => x.moduleCode == Constants.ADMIN_MODULE_CODE);
      this.modulePermissions = result[0].modulePermissions;
    });
  }

  /**
   * Method to determine if we can activate the view based on custom logic.
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {


    //no valid access token ;
    //console.log("AUTHGUARD");
    if(!this.oauthService.HasValidToken()&&!this.oauthService.IsloginInProgress()&& !this.oauthService.IsloginOutProgress())
    {
      // console.log("No valid accees token. Redirecting to login.");
        this.oauthService.Login(document.location.href);
        return false;
    }
    //console.log("Found valid access token. Checking user permissions.");
    var permissions: string[] = [];
    this.modulePermissions.forEach(element => {
      permissions.push(Constants.ADMIN_MODULE_CODE + "_" + element.permissionCode);
    });
    var userPermissions: string[] = [];
    if(JSON.parse(this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)) != null)
        userPermissions =  userPermissions.concat(JSON.parse(this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)));    
    if (userPermissions!=null&& userPermissions!=undefined && userPermissions.length > 0) {
      //console.log(userPermissions.length );
    
      var hasPermissions = this.permissionsService.checkUserPermissions(permissions, false);
      if (!hasPermissions)
        this.router.navigate(["AccessDenied"]);
      return true;
    }
    else {
      return false;
    }

  }
}
