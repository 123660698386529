import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { TenantModel, TenantModulesModel, TenantModulesPermissionModel, TenantJobDesignationModel, TenantLocationTypeModel, TenantBussinessTypeModel, TenantLocale, TenantMetaDataModel } from 'src/app/models/tenantModel';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { Constants } from '../../shared/shared.constants';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CORE_SESSION_STORAGE } from '../storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';



@Injectable({
  providedIn: 'root'
})
/**
 * Service class.
 */
export class TenantService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = '/api/tenant';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient,
    @Optional() appConfigService: AppConfigService,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      this._uri = `${this._appConfigService.get().tenantConfig.coreService.serviceUrl}` + this._uri;
    }
  }

  /**
   * Pulls a list of modules
   * @returns {Observable<RoleModel[]>}
   */
  getTenantModules(): Observable<TenantModulesModel[]> {

    var persistedData = this.sessionStorage.get(Constants.TENANT_MODULES);
    if (persistedData !== undefined && persistedData != null && persistedData != '') { 
    return of(<TenantModulesModel[]>JSON.parse(persistedData));
  }
  else{
    var endpoint = '/Modules';
    return this.http.get<TenantModulesModel[]>(`${this._uri}${endpoint}`).pipe(flatMap(res =>{
      if (res != null)
        this.sessionStorage.set(Constants.TENANT_MODULES, JSON.stringify(res));
    return of(res);
    }));
  }
    
  }

  /**
   * Pulls a list of metadata
   * @returns {Observable<TenantMetaDataMode]>}
   */
  getTenantMetadata(): Observable<TenantMetaDataModel> {
    const persistedData = this.sessionStorage.get(Constants.TENANT_METADATA);
    if (persistedData !== undefined && persistedData != null && persistedData != '') {
      return of(<TenantMetaDataModel>JSON.parse(persistedData));
    } else {
      return this.http.get<any>(`${this._uri}`)
        .pipe(flatMap(userInfo => {
          if (userInfo != null) {
            this.sessionStorage.set(Constants.TENANT_METADATA, JSON.stringify(userInfo.metaData));
          }
          return of(userInfo.metaData);
        }));
    }
  }

  /**
 * Pulls a list of Roles objects.
 * @returns {Observable<RoleModel[]>}
 */
  getTenantJobDesignations(): Observable<TenantJobDesignationModel[]> {
    var endpoint = '/JobDesignations';
    return this.http.get<TenantJobDesignationModel[]>(`${this._uri}${endpoint}`);
  }

  /**
* Pulls a list of tenant location type objects.
* @returns {Observable<TenantLocationTypeModel[]>}
*/
  getTenantLocationTypes(): Observable<TenantLocationTypeModel[]> {
    var endpoint = '/GetTenantLocationTypes';
    return this.http.get<TenantLocationTypeModel[]>(`${this._uri}${endpoint}`);
  }

  /**
* Pulls a list of tenant location type objects.
* @returns {Observable<TenantBussinessTypeModel[]>}
*/
  getTenantBussinessTypes(): Observable<TenantBussinessTypeModel[]> {
    var endpoint = '/GetAllBussinessTypes';
    return this.http.get<TenantBussinessTypeModel[]>(`${this._uri}${endpoint}`);
  }

  /**
* Pulls a list of tenant location type objects.
* @returns {Observable<TenantBussinessTypeModel[]>}
*/
  getTenantJobRolesByBussinessTypes(bussinessCode: string[]): Observable<TenantJobDesignationModel[]> {
    var qstring = [];
    bussinessCode.forEach(x => {
      qstring.push('bussinessTypeCodes=' + x)
    });
    var endpoint = '/JobRolesForBussinessType?' + qstring.join('&');
    return this.http.get<TenantJobDesignationModel[]>(`${this._uri}${endpoint}`);
  }

  /**
* Pulls a list of tenant locales.
* @returns {Observable<TenantLocale[]>}
*/
  getTenantLocales(): Observable<TenantLocale[]> {
    var endpoint = '/Locales';
    return this.http.get<TenantLocale[]>(`${this._uri}${endpoint}`);
  }
}
