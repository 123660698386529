import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { RoleModel } from 'src/app/models/roleModel';
import { dataGridModel } from 'src/app/models/dataGridModel';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { Constants } from '../../shared/shared.constants';



@Injectable()
/**
 * Service class.
 */
export class RolesService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = '/api/role';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      this._uri = `${this._appConfigService.get().tenantConfig.authService.serviceUrl}` + this._uri;
    }
  }

  /**
  * Pulls a list of Roles objects.
  * @returns {Observable<RoleModel[]>}
  */
  getRole(roleId: number): Observable<RoleModel> {
    var endpoint = `/${roleId}`;
    return this.http.get<RoleModel>(`${this._uri}${endpoint}`);
  }

  /**
   * Pulls a list of Roles objects.
   * @returns {Observable<RoleModel[]>}
   */
  list<T>(searchParameters: dataGridModel<T>): Observable<RoleModel[]> {
    var endpoint = '/all';
    return this.http.post<RoleModel[]>(`${this._uri}${endpoint}`, searchParameters);
  }


  /**
  * Updates the role.
  * @returns {Observable<any>}
  */
  update(model: RoleModel): Observable<any> {
    var endpoint = '';
    if (model.id > 0)
      return this.http.put<any>(`${this._uri}${endpoint}/${model.id}`, model);
    else
      return this.http.post<any>(`${this._uri}${endpoint}`, model);
  }

  /**
  * delete the role
  *  * @returns {Observable<any>}
  */
  deleteRole(roleId: number): Observable<any> {
    var endpoint = `/${roleId}`;
    return this.http.delete<any>(`${this._uri}${endpoint}`);
  }
}
