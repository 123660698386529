import { Pipe, PipeTransform } from '@angular/core'
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { mergeMap, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


@Pipe({ name: 'jobRoleName' })
export class JobRoleNamePipe implements PipeTransform {
  constructor(private tenantService: TenantService) {

  }
  transform(value: string, args: string): any {

    return this.tenantService.getTenantJobDesignations()
      .pipe(flatMap(locs => {
        var filter = locs.filter(f => f.jobDesignationCode == value);
        return filter.length > 0 ? of(filter[0].jobDesignationName) : of("")
      }));
  }
}
