import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { PermissionsService } from '../../../services/permissions/permissions.service';
import { element } from 'protractor';

@Component({
  selector: 'permission-block',
  template: `
<ng-content *ngIf="accessGranted"></ng-content>
<ng-content select="else" *ngIf="!accessGranted"></ng-content>
`
})
export class PermissionBlockComponent implements OnInit {
  @Input() public permissions: string[] = [];
  @Input() public allPermissions: boolean = false;
  accessGranted: boolean = false;
  constructor(private permissionService: PermissionsService) {


  }

  ngOnInit(): void {
    this.accessGranted = this.permissionService.checkUserPermissions(this.permissions, this.allPermissions);
    //console.log(this.accessGranted);
  }
}
