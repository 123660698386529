<div
    id="header"
    class="mdk-header bg-dark js-mdk-header m-0"
    data-fixed=""
    data-effects="waterfall"
    data-domfactory-upgraded="mdk-header"
    [ngStyle]="{ 'padding-top': adminView ? 0 : '64px' }"
>
    <div class="mdk-header__bg">
        <div class="mdk-header__bg-front"></div>
        <div class="mdk-header__bg-rear"></div>
    </div>
    <div class="mdk-header__content">
        <nav
            id="default-navbar"
            class="navbar navbar-expand navbar-dark bg-primary m-0 mdk-header--fixed mdk-header--shadow"
            data-primary="data-primary"
        >
            <div [ngClass]="containerClass">
                <button
                    class="navbar-toggler d-block d-xl-none d-lg-none"
                    onclick="toggleSidebar()"
                    type="button"
                >
                    <span class="material-icons">menu</span>
                </button>
                <a [href]="GetUserViewRoute()" class="navbar-brand">
                    <img
                        src="../../../../assets/themes/learning/images/logo/white.svg"
                        class="mr-2"
                        alt="LearnPlus"
                    />
                    <span class="d-none d-xs-md-block">
                        {{
                            "core-site-name"
                                | translate: '{defaultText:  "LearningHub"}'
                        }}
                    </span>
                </a>

                <form
                    class="search-form d-none d-md-flex"
                    [formGroup]="homepageSearchForm"
                    (submit)="onSearchSubmit()"
                >
                    <input
                        type="text"
                        class="form-control"
                        formControlName="searchtext"
                        placeholder="{{
                            'core-homepage-search_searchplaceholder'
                                | translate: '{ defaultText: \'Search\'}'
                        }}"
                    />
                    <button class="btn" type="submit">
                        <i class="material-icons font-size-24pt">search</i>
                    </button>
                </form>

                <div class="flex"></div>
                <!-- TODO: add menu items if needed -->
                <!-- <ul class="nav navbar-nav flex-nowrap d-none d-lg-flex">
          <li class="nav-item">
            <a class="nav-link" href="fixed-student-help-center.html">Get Help</a>
          </li>
        </ul> -->

                <ul class="nav navbar-nav flex-nowrap">
                    <!-- TODO: add notifications feature -->
                    <li
                        class="nav-item dropdown dropdown-notifications dropdown-menu-sm-full"
                    >
                        <button
                            class="nav-link btn-flush dropdown-toggle d-none d-sm-flex"
                            type="button"
                            data-toggle="dropdown"
                            data-dropdown-disable-document-scroll=""
                            data-caret="false"
                            *ngIf="!adminView"
                        >
                            <i class="material-icons">notifications</i>
                            <span class="badge badge-notifications badge-danger"
                                >2</span
                            >
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <div
                                data-perfect-scrollbar=""
                                class="position-relative ps"
                            >
                                <div class="dropdown-header">
                                    <strong>Messages</strong>
                                </div>
                            </div>
                        </div>
                    </li>
                    <user-profile-menu
                        [adminView]="adminView"
                    ></user-profile-menu>
                </ul>
            </div>
        </nav>
    </div>
</div>

<div class="mdk-header-layout__content d-flex flex-column" *ngIf="!adminView">
    <div class="page__header">
        <div
            class="navbar bg-dark navbar-dark navbar-expand-sm d-none2 d-md-flex2"
        >
            <div class="container">
                <div class="navbar-collapse collapse" id="navbarsExample03">
                    <ul class="nav navbar-nav">
                        <li
                            class="nav-item"
                            *ngFor="let item of items"
                            [ngClass]="{
                                active: isActive(item),
                                dropdown: itemHasChildren(item)
                            }"
                        >
                            <a
                                class="nav-link"
                                [routerLink]="[item.routerLink]"
                                title="{{ item.label }}"
                                *ngIf="!itemHasChildren(item)"
                                (click)="changeMenu(item)"
                            >
                                {{
                                    "core-common-user_menu_" + item.label
                                        | lowercase
                                        | translate
                                            : "{defaultText: '" +
                                                  item.label +
                                                  "'}"
                                }}
                            </a>
                            <a
                                href="javascript:void(0);"
                                class="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                                title="{{ item.label }}"
                                *ngIf="itemHasChildren(item)"
                                (click)="changeMenu(item)"
                            >
                                {{
                                    "core-common-user_menu_" + item.label
                                        | lowercase
                                        | translate
                                            : "{defaultText: '" +
                                                  item.label +
                                                  "'}"
                                }}
                            </a>
                            <div
                                class="dropdown-menu"
                                *ngIf="itemHasChildren(item)"
                            >
                                <a
                                    class="dropdown-item"
                                    [routerLink]="[childItem.routerLink]"
                                    title="{{ childItem.label }}"
                                    *ngFor="let childItem of item.items"
                                >
                                    {{
                                        "core-common-user_menu_" +
                                            childItem.label
                                            | lowercase
                                            | translate
                                                : "{defaultText: '" +
                                                      childItem.label +
                                                      "'}"
                                    }}
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

                <button
                    class="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbarsExample03"
                    type="button"
                >
                    <span class="material-icons">menu</span>
                </button>
            </div>
        </div>
    </div>
</div>
