import { Component, ContentChild, Input, TemplateRef, Directive, ViewChild, ViewContainerRef, ViewEncapsulation, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'synapze-cx-grid-column',
  template: `
<div (click)="clicked()">
  <ng-content></ng-content>
</div>
  `,
  encapsulation: ViewEncapsulation.None
})
export class SynapzeCXGridColumnComponent {

  @Output() columnClicked: EventEmitter<string> = new EventEmitter();
  // Read in our structural directives as TemplateRefs
  @ViewChild("columnContainer", { read: ViewContainerRef }) container;
  @Input() columnId: string = '';
  @Input() sortOrder: string = '';
  clicked() {
    if (this.columnId != null && this.columnId!='')
      this.columnClicked.emit(this.columnId);
  }

}



