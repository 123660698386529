export class AssessmentPermissions {
  static readonly EXPORT_REPORTS: string = 'ASSESSMENT_EXPORT_REPORTS';
  static readonly DELETE_ASSESSMENT: string = 'ASSESSMENT_DELETE_ASSESSMENT';
  static readonly ADD_EDIT_QUESTIONS: string = 'ASSESSMENT_ADD_EDIT_QUESTIONS';
  static readonly ADD_ASSESSMENT: string = 'ASSESSMENT_ADD_ASSESSMENT';
  static readonly PUBLISH_ASSESSMENT: string = 'ASSESSMENT_PUBLISH_ASSESSMENT';
  static readonly VIEW_REPORTS: string = 'ASSESSMENT_VIEW_REPORTS';
  static readonly IMPORT_QUESTIONS: string = 'ASSESSMENT_IMPORT_QUESTIONS';
  static readonly DELETE_QUESTIONS: string = 'ASSESSMENT_DELETE_QUESTIONS';
  static readonly EDIT_ASSESSMENT: string = 'ASSESSMENT_EDIT_ASSESSMENT';
  static readonly PREVIEW_ASSESSMENT: string = 'ASSESSMENT_PREVIEW_ASSESSMENT';

  constructor() {

  }

  public  GetAdminPermissions(): string[] {
    const permissions = [];
    permissions.push(AssessmentPermissions.DELETE_ASSESSMENT);
    permissions.push(AssessmentPermissions.ADD_EDIT_QUESTIONS);
    permissions.push(AssessmentPermissions.ADD_ASSESSMENT);
    permissions.push(AssessmentPermissions.PUBLISH_ASSESSMENT);
    permissions.push(AssessmentPermissions.IMPORT_QUESTIONS);
    permissions.push(AssessmentPermissions.DELETE_QUESTIONS);
    permissions.push(AssessmentPermissions.EDIT_ASSESSMENT);
    permissions.push(AssessmentPermissions.PREVIEW_ASSESSMENT);
    return permissions;
  }

  public GetReportPermissions(): string[] {
    const permissions = [];
    permissions.push(AssessmentPermissions.EXPORT_REPORTS);
    permissions.push(AssessmentPermissions.VIEW_REPORTS);
    return permissions;
  }

}
