import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, forwardRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";


@Component({
  selector: 'synapze-cx-rich-text',
  templateUrl: './synapze-cx-rich-text.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SynapzeCxRichTextComponent),
    multi: true,
  }]
})
export class SynapzeCxRichTextComponent implements ControlValueAccessor {

  
  @Input() height: string = "200px";
  @Input() onlyFontWeight: boolean = false;
  model: string= "";
  _inputText: string = "";
  public set inputText(value: string) {
    this._inputText = value;
    //if (this.onChange != null)
    //  this.onChange(this._inputText);
  }
  public get inputText(): string {
    return this._inputText;
  }
  
  _readOnly: boolean = false;


  @Input()
  public set readOnly(value: boolean) {
    this._readOnly = value;

    if (this.textForm != null) {
      if (this._readOnly)
        this.textForm.disable();
      else
        this.textForm.enable();
    }

  }
  public get readOnly(): boolean {
    return this._readOnly;
  }

  onChange;
  textForm: FormGroup;
  ngOnInit(): void {
    this.textForm = new FormGroup({
      description: new FormControl()
    });

    if (this._readOnly)
      this.textForm.disable();
    else
      this.textForm.enable();

  }

  writeValue(obj: any): void {
    //this.inputText = obj;
    this.textForm.controls["description"].setValue(obj);
    this.model = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    //this.onChange = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readOnly = isDisabled;
  }

  constructor() {
  }

  ngAfterViewInit() {
  }

  textChange(changeEvent): void {
    this.onChange(changeEvent.htmlValue);
    this.model = changeEvent.htmlValue;
  }

  onEditorInit(event: any) {
    //event.editor.root.blur();
    //window.scroll(0, 0);
  }


}
