import { Component, OnInit, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, ViewChild, forwardRef } from '@angular/core';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { LocationsService } from 'src/app/services/locations/locations.service';
import { DropDownItemModel } from "src/app/models/DropDownItemModel";
import { FormGroup, FormBuilder, Validators, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { UsersService } from 'src/app/services/users/users.service';
import { TranslateService } from '@ngx-translate/core';


export const LOCATION_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TenantLocationSelectorComponent),
  multi: true,
};

@Component({
  selector: 'tenant-location-selector',
  templateUrl: './tenant-location-selector.component.html',
  providers: [LOCATION_VALUE_ACCESSOR],
})
export class TenantLocationSelectorComponent implements OnInit,ControlValueAccessor {

  editLocationForm: FormGroup;
  @Input() locations: string[] = [];

  @Output() locationsChange = new EventEmitter();
  tagName: string = "";
  tenantLocationTypes: DropDownItemModel[] = [];
  _tenantLocationTypeNames: DropDownItemModel[] = []
  return_msg: string;

  public get tenantLocationTypeNames(): DropDownItemModel[] {
    return this._tenantLocationTypeNames;
  }
  public set tenantLocationTypeNames(value: DropDownItemModel[]) {
    if (this.locations == undefined) {
      this._tenantLocationTypeNames = value;
    }
    else if (value) {
      this._tenantLocationTypeNames = value.filter(loc => this.locations.indexOf(loc.value) == -1);
    }
  }
  onChange;
  _readOnly: boolean = false;

  @Input()
  public set readOnly(value: boolean) {
    this._readOnly = value;
  }
  public get readOnly(): boolean {
    return this._readOnly;
  }

  @Input() showLabelOnTop:boolean= true;

  constructor(private tenantService: TenantService,
    private formBuilder: FormBuilder,
    private locationService: LocationsService,
    private userService: UsersService,
    private readonly translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.get(["admin-common-selectalocation_title"], { defaultText: "Location" })
      .subscribe((res: string) => {
        this.return_msg = res["admin-common-selectalocation_title"];
      });

    var test = false;
    this.userService.events().subscribe(message => {
      if (message.event == UsersService.CURRENT_USER_FETCHED_EVENT) {
        //this.callGTM(this.userService.currentUser());
        if (!test) {
          this.getLocationTypes(this.userService.currentUser().browsingLanguagePreference);
        }
        test = true;
      }
    });

    var userData = this.userService.currentUser();
    if (userData != null)
      this.getLocationTypes(userData.browsingLanguagePreference);

    this.editLocationForm = this.formBuilder.group({
      locationtype: new FormControl(""),
      locationname: new FormControl("")
    });

    if (this.readOnly)
      this.editLocationForm.disable();
    else
      this.editLocationForm.enable();


    this.editLocationForm.controls['locationtype'].valueChanges.subscribe(
      value => {
        this.editLocationForm.controls['locationname'].setValue([]);
        this.getLocationTypeNames(value);
        
      });

  }

  public getLocationTypeLabel(value): string {
    var filter = this.tenantLocationTypes.filter(a => a.value == value);
    if (filter != null && filter.length > 0)
      return filter[0].label;

    return this.return_msg;
  }



  private getLocationTypes(currentLocale: string): any {
    this.tenantService.getTenantLocationTypes().subscribe(locationTypeInfos => {
      //console.log(currentLocale);
      var loc = locationTypeInfos.filter(a => a.locationLocale == currentLocale);
      this.tenantLocationTypes = loc.map((v) => {
        return { label: v.locationType, value: v.locationTypeLevel }
      });

    });
  }




  private getLocationTypeNames(locationTypeLevel: any): void {
    
    this.tenantLocationTypeNames = [];
    if (locationTypeLevel != null && locationTypeLevel != '') {

      this.locationService.getLocationsbyBizCodeAndLevel("", locationTypeLevel).subscribe(locs => {
        this.tenantLocationTypeNames = locs.map((v) => { return { label: v.formattedLocationName, value: v.locationNodeString } });
      });
    }
  }

  deleteLocation(index): void {
    if (!this.readOnly) {
      this.locations.splice(index, 1);
      this.getLocationTypeNames(this.editLocationForm.controls['locationtype'].value);
      this.fireLocationChangeEvent();
    }
  }

  addLocation(): void {
    if (this.locations == null)
      this.locations = [];

    this.locations.push(this.editLocationForm.controls['locationname'].value);
    this.editLocationForm.controls['locationname'].setValue([]);
    this.editLocationForm.controls['locationtype'].setValue([]);
    this.fireLocationChangeEvent();
  }

  fireLocationChangeEvent(): void {
    this.locationsChange.emit(this.locations);
    if (this.onChange != null)
      this.onChange(this.locations);
  }

  writeValue(obj: any): void {
    this.locations = obj;
  }

  registerOnChange(fn: any): void {
   this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    this._readOnly = isDisabled;
  }
}
