import { Pipe, PipeTransform } from '@angular/core'
import { RolesService } from 'src/app/services/roles/roles.service';
import { mergeMap, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { dataGridModel } from '../models/dataGridModel';


@Pipe({ name: 'tenantRoleName' })
export class TenantRoleNamePipe implements PipeTransform {
  constructor(private rolesService: RolesService) {
  }
  transform(value: string, args: string): any {

    var searchParams = new dataGridModel <{}>()
    return this.rolesService.list(searchParams)
      .pipe(flatMap(locs => {
        var filter = locs.filter(f => f.id.toString() == value);
        return filter.length > 0 ? of(filter[0].name) : of("")
      }));
  }
}
