import { TenantLocationTypeModel } from './../../models/tenantModel';
import { Injectable, Optional } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

import { Location } from 'src/app/vos/location/location';
import { AppConfigService } from '../app-config/app-config.service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class LocationsServiceConfig {
    uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class LocationsService {

    /**
     * Path uri.
     * @type {string}
     * @private
     */
     private _uri = '/api/';

    /**
     * Url to endpoint api.
     * @type {string}
     */
     private endpoint = 'location';

     private _appConfigService: AppConfigService;
    /**
     * Endpoint request headers.
     * @type {HttpHeaders}
     */
     private headers = new HttpHeaders({'Content-Type': 'application/json'});

    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {LocationsServiceConfig} config
     */
    constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService) {
        this._appConfigService = appConfigService;
        if (this._appConfigService) {
          this._uri = `${this._appConfigService.get().tenantConfig.coreService.serviceUrl}` + this._uri;
        }
    }

    /**
     * Pulls a list of Location objects.
     * @returns {Observable<Location[]>}
     */
    list(): Observable<Location[]> {
            return this.http.get<Location[]>(`${this._uri}${this.endpoint}`);
    }

    /**
     * Pulls a list of Location objects.
     * @returns {Observable<Location[]>}
     */
    getLocationsbyBizCodeAndLevel(bussinessCode:string,locationLevel:number): Observable<Location[]> {
        var query = "/GetLocationsAtLevel?bussinessTypeCode="+bussinessCode+"&level="+locationLevel;
        return this.http.get<Location[]>(`${this._uri}${this.endpoint}${query}`);
    }

    /**
     * Pulls a list of Location objects.
     * @returns {Observable<Location[]>}
     */
    getLocationTypesbyBizCode(bussinessCode:string): Observable<TenantLocationTypeModel[]> {
        var query = "/GetLocationTypesByBusinessType?bussinessTypeCode="+bussinessCode;
        return this.http.get<TenantLocationTypeModel[]>(`${this._uri}${this.endpoint}${query}`);
    }


    /**
     * Pulls a single Location object.
     * @param {number | string} id to retrieve.
     * @returns {Observable<Location>}
     */
    show(id: number | string): Observable<Location> {
        const url = `${this._uri}${this.endpoint}/${id}`;
    return this.http.get<Location>(url);
    }


    /**
     * Creates a single Location object.
     * @param {} value to create.
     * @returns {Observable<Location>}
     */
    create(value: Location): Observable<Location> {
        return this.http
            .post<Location>(`${this._uri}${this.endpoint}`, JSON.stringify(value), {headers: this.headers});
    }


    /**
     * Updates a single Location object.
     * @param {} value to update.
     * @returns {Observable<Location>}
     */
    update(value: Location): Observable<Location> {
        const url = `${this._uri}${this.endpoint}/${value.locationId}`;
    return this.http
        .put<Location>(url, JSON.stringify(value), {headers: this.headers});
    }


    /**
     * Destroys a single Location object.
     * @param {number | string} id to destroy.
     * @returns {Observable<void>}
     */
    destroy(id: number | string): Observable<void> {
        const url = `${this._uri}${this.endpoint}/${id}`;
        return this.http.delete<void>(url, {headers: this.headers});
    }


}
