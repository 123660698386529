<div class="row justify-content-start">
  <button class="btn btn-primary" (click)="locationLookupModalTemplate.show()">{{invokeLookupButtonText!=undefined ? invokeLookupButtonText : 'admin-common-addlocationbutton_label' | translate: '{ defaultText : \'+ Add Locations\'}' }}</button>
</div>
<synapze-cx-modal #locationLookupModalTemplate>
  <div header>
    {{'admin-common-locations_header' | translate : '{ defaultText: "Locations"}'}}
    <div class="job-roles-sub-header m-t-5">{{'admin-common-locations_message' | translate : '{ defaultText: "Select Locations by Country, Province or City, to add them to this Group."}'}}</div>
  </div>
  <div body>
    <form [formGroup]="editLocationForm">
      <tenant-location-selector [readOnly]="false" formControlName="locations" [(locations)]="locationCodes" (locationsChange)="locationsChange($event)"></tenant-location-selector>
    </form>
    <div class="row m-t-40">
      <div class="col-lg-6 selectedAmountModal">
        {{locationCodes ? locationCodes.length : 0 }} {{'admin-common-selected_label' | translate : { defaultText: "Selected"} }}
      </div>
      <div class="col-lg-6 action-selected-buttons">
        <button class="btn btn-light mr-15" type="button" (click)="locationLookupModalTemplate.hide()"> {{'admin-common-cancel' | translate: { defaultText : 'Cancel'} }}</button>
        <button class="btn btn-primary" type="button" (click)="locationsSelected()">{{'admin-common-addselectedbutton_label' | translate: { defaultText : 'Add Selected'} }}</button>
      </div>
    </div>
  </div>
</synapze-cx-modal>
