export class TagManager {
  private static instance: TagManager = null;
  private ids: string[];
  private auth: string;
  private preview: string;
  private dataLayerName: string;
  private precision: number = 2;

  public dataLayer: any[] = [];
  public language: string;
  public currency: string;

  constructor(ids: string[], auth: string, preview: string, dataLayerName: string, language: string, currency: string) {
    if (TagManager.instance) {
      throw new Error("Error - use GoogleTagManager.getInstance()");
    }
    this.ids = ids;
    this.dataLayerName = dataLayerName;
    this.auth = auth;
    this.preview = preview;
    this.currency = currency;
    this.language = language;
    this.init();
  }

  private init() {
    this.ids.forEach(id => this.initContainer(id, this.auth, this.preview));
  }

  private initContainer(id: string, auth: string, preview: string) {
    let internal = this;
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      let f: any = d.getElementsByTagName(s)[0];
      let j: any = d.createElement(s);
      let dl: any = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
      internal.dataLayer = w[l];
    })(window, document, 'script', this.dataLayerName, id);
  }

  public static getInstance(ids: string | string[] = [], auth: string, preview: string, dataLayerName = 'dataLayer', language = 'en', currency = 'EUR') {
    ids = ids || [];
    ids = Array.isArray(ids) ? ids : [ids];
    if (!TagManager.instance) {
      if (ids.length === 0) {
        return;
      }
      ids.forEach(id => {
        if (!id || (typeof id !== 'string') || id.indexOf('GTM-') !== 0) {
          console.error("Please provide a valid container ID (i.e. GTM-XXXXXX)");
        }
      })
      if (!dataLayerName || (typeof dataLayerName !== 'string')) {
        console.error("Please provide a valid name for the data layer");
      }
    }
    TagManager.instance = TagManager.instance || new TagManager(ids, auth, preview, dataLayerName, language, currency);
    return TagManager.instance;
  }
}
