import { Router, ActivatedRoute, Params, DefaultUrlSerializer, UrlTree } from '@angular/router';
import { OnInit, Component, Injectable } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  templateUrl: '404.component.html',  
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class P404Component implements OnInit {
  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute) { }
  public isLogin: boolean

  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.isLogin = this.location.path(true).indexOf('id_token') != -1;
  }
}
