import { Component, OnInit, ViewChild, Input, EventEmitter, Output, forwardRef, ViewContainerRef } from '@angular/core';
import { SynapzeCxModalComponent } from '../synapze-cx-modal/synapze-cx-modal.component';
import { FormGroup, NG_VALUE_ACCESSOR, FormBuilder, FormControl } from '@angular/forms';

export const LOCATION_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TenantLocationSelectorModalComponent),
  multi: true,
};

@Component({
  selector: 'tenant-location-selector-modal',
  templateUrl: './tenant-location-selector-modal.component.html',
  providers: [LOCATION_VALUE_ACCESSOR],
})
export class TenantLocationSelectorModalComponent implements OnInit {

  @Input() invokeLookupButtonText: string;

  @Input() locationCodes: string[] = [];

  @Output() onLocationsSelected = new EventEmitter<string[]>();

  @ViewChild("locationLookupModalTemplate") jobRoleLookupModalTemplate: SynapzeCxModalComponent;

  editLocationForm: FormGroup;
  onChange;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.editLocationForm = this.formBuilder.group({
      locations: new FormControl(this.locationCodes)
    });
  }

  locationsChange(evt) {
    this.locationCodes = evt;
    
  }

  locationsSelected() {
    this.fireLocationChangeEvent();
    this.onLocationsSelected.emit(this.locationCodes);
    this.jobRoleLookupModalTemplate.hide();
  }

  fireLocationChangeEvent(): void {
    if (this.onChange != null)
      this.onChange(this.locationCodes);
  }

  writeValue(obj: any): void {
    this.locationCodes = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
}
