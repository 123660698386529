import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Meta } from '@angular/platform-browser';


export interface Confirmation {
  message: string;
  key?: string;
  icon?: string;
  header?: string;
  accept?: Function;
  reject?: Function;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptVisible?: boolean;
  rejectVisible?: boolean;
  acceptEvent?: EventEmitter<any>;
  rejectEvent?: EventEmitter<any>;
}

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {


  //@Input() modalClass: string = "modal-lg modal-info";
  @Input() key: string = "default";

  constructor(private confirmationService: ConfirmationService) {
  }

  ngAfterViewInit() {
  }

  //public confirm(message: string, accept: Function, reject: Function): void {
  public confirm(metaData: Confirmation): void {
    this.confirmationService.confirm({
      key: this.key,
      message: metaData.message,
      accept: () => {
        if (metaData.accept != null)
          metaData.accept();
      },
      reject: () => {
        if (metaData.reject != null)
          metaData.reject();
      },
      acceptVisible: metaData.acceptVisible,
      rejectVisible: metaData.rejectVisible,
      rejectLabel: metaData.rejectLabel,
      acceptLabel: metaData.acceptLabel,
      header: metaData.header
    });

  }

  
  //public hide(): void {
  //  if (!this.fancy) {
  //    if (this.modalRef !=null)
  //      this.modalRef.hide();
  //  }
  //  else {
  //    this.swalComponent.nativeSwal.close();
  //  }
  //}
}
