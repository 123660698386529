import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef, ViewContainerRef } from '@angular/core';
import { SynapzeCxModalComponent } from '../synapze-cx-modal/synapze-cx-modal.component';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, FormControl } from '@angular/forms';

export const JOB_ROLE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => JobRoleTenantRoleSelectorModalComponent),
  multi: true,
};


@Component({
  selector: 'job-role-tenant-role-selector-modal',
  templateUrl: './job-role-tenant-role-selector-modal.component.html',
  providers: [JOB_ROLE_VALUE_ACCESSOR],
})
export class JobRoleTenantRoleSelectorModalComponent implements OnInit {

  @Input() invokeLookupButtonText: string;

  @Input() jobRoleCodes: string[] = [];

  @Output() onJobRolesSelected = new EventEmitter<string[]>();

  @ViewChild("jobRoleLookupModalTemplate") jobRoleLookupModalTemplate: SynapzeCxModalComponent;
  
  onChange;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  jobRoleCodesChange(evt) {
    this.jobRoleCodes = evt;
  }

  jobRolesSelected() {
    this.fireJobRoleChangeEvent();
    this.onJobRolesSelected.emit(this.jobRoleCodes);
    this.jobRoleLookupModalTemplate.hide();
  }

  fireJobRoleChangeEvent(): void {
    if (this.onChange != null)
      this.onChange(this.jobRoleCodes);
  }

  writeValue(obj: any): void {
    this.jobRoleCodes = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }

}
