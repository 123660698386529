<div class="row justify-content-start">
  <button class="btn btn-primary" (click)="jobRoleLookupModalTemplate.show()">{{invokeLookupButtonText!=undefined ? invokeLookupButtonText : 'admin-common-addjobrolebutton_label' | translate: '{ defaultText : \'+ Add Job Roles\'}' }}</button>
</div>
<synapze-cx-modal #jobRoleLookupModalTemplate>
  <div header>
    {{'admin-common-jobroles_header' | translate : '{ defaultText: "Job Roles"}'}}
    <div class="job-roles-sub-header m-t-5">{{'admin-common-jobroles_message' | translate : { defaultText: "Select Job Roles to Add them to this Group."} }}</div>
  </div>
  <div body>
    <div class="row">
      <div class="col-md-12">
        <label class="m-b-5">{{'admin-common-select_jobrole' | translate : '{ defaultText: "Select Job Roles"}'}}</label>
        <jobRole-tenantRole-selector placeholder="{{'admin-common-assign_job_roles' | translate : { defaultText : 'ASSIGN JOB ROLES' } }}"
          [readOnly]="false" [source]="'editRole'" (jobRoleCodesChange)="jobRoleCodesChange($event)"
          [jobRoleCodes]="jobRoleCodes">
        </jobRole-tenantRole-selector>
      </div>
    </div>
    <div class="row m-t-40">
      <div class="col-lg-6 selectedAmountModal">
        {{jobRoleCodes ? jobRoleCodes.length : 0 }} {{'admin-common-selected_label' | translate : { defaultText: "Selected"} }}
      </div>
      <div class="col-lg-6 action-selected-buttons">
        <button class="btn btn-light mr-15" type="button" (click)="jobRoleLookupModalTemplate.hide()"> {{'admin-common-cancel' | translate: { defaultText : 'Cancel'} }}</button>
        <button class="btn btn-primary" type="button" (click)="jobRolesSelected()">{{'admin-common-addselectedbutton_label' | translate: { defaultText : 'Add Selected'} }}</button>
      </div>
    </div>
  </div>
</synapze-cx-modal>
