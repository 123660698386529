import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { error } from '@angular/compiler/src/util';


@Component({
  selector: 'validation-error',
  templateUrl: './validation-error.component.html'
})
export class ValidationErrorComponent implements OnInit, OnChanges {

  @Input() errors = [];
  @Input() field: string;
  error: any;
  ngOnInit(): void {
   
  }
  ngOnChanges(changes: SimpleChanges)  {
   // console.log("ERRORVAL");
    if (this.errors != null && this.field != null) {
      // this.error = this.errors.filter(x => x.errorFields.filter(y=> y == this.field).length > 0)[0];
      // console.log(this.error);
      // console.log(this.field);
      // console.log(this.errors);
    }
  }
}
