import { Component, AfterViewInit } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { TenantModuleResolutionService } from "src/app/services/tenant/tenant-navigation-resolution-service";
import { NavigationItem } from "src/app/vos/app-config/app-config";
import { AssessmentPermissions } from "src/app/modules/assessment/permissions";
import { ResourceLibraryPermissions } from "src/app/modules/resourceLibrary/permissions";

@Component({
    selector: "app-dashboard",
    templateUrl: "./default-layout.component.html",
    host: {
        class: " ",
    },
    styles: [":host {display: block; padding-top: 64px; height: 100vh;}"],
})
export class DefaultLayoutComponent implements AfterViewInit {
    private navMenuItems: NavigationItem[] = [];

    public navigationLinks: any[];
    public reportsLinks: any;
    constructor(
        private tenantNavResolutionService: TenantModuleResolutionService,
        public router: Router
    ) {
        const assessmentPermissions = new AssessmentPermissions();
        const resourceLibraryPermissions = new ResourceLibraryPermissions();

        this.navMenuItems = [
            {
                moduleCode: "ADMIN",
                route: "/admin",
                displayName: "",
                useExternalRoute: false,
                icon: "settings",
                isCollapsed: true,
                permissions: [
                    "ADMIN_ACTIVATE_USER",
                    "ADMIN_VIEW_USER",
                    "ADMIN_UPDATE_ROLE",
                    "ADMIN_UPDATE_SYSTEM_ROLE",
                    "ADMIN_DELETE_SYSTEM_ROLE",
                    "ADMIN_CREATE_ROLE",
                    "ADMIN_CREATE_SYSTEM_ROLE",
                    "ADMIN_RESETPWD_USER",
                    "ADMIN_DELETE_ROLE",
                    "ADMIN_APPROVE_USER",
                    "ADMIN_DEACTIVATE_USER",
                    "ADMIN_DENY_USER",
                    "ADMIN_UPDATE_USER",
                ],
                children: [
                    {
                        displayName: "Role Manager",
                        route: "admin/roles",
                        permissions: [
                            "ADMIN_UPDATE_ROLE",
                            "ADMIN_UPDATE_SYSTEM_ROLE",
                            "ADMIN_DELETE_SYSTEM_ROLE",
                            "ADMIN_CREATE_ROLE",
                            "ADMIN_CREATE_SYSTEM_ROLE",
                            "ADMIN_DELETE_ROLE",
                        ],
                    },
                    {
                        displayName: "User Manager",
                        route: "admin/users",
                        permissions: [
                            "ADMIN_ACTIVATE_USER",
                            "ADMIN_VIEW_USER",
                            "ADMIN_RESETPWD_USER",
                            "ADMIN_DEACTIVATE_USER",
                            "ADMIN_UPDATE_USER",
                        ],
                    },
                    {
                        displayName: "Groups Manager",
                        route: "admin/groups",
                        permissions: [
                            "ADMIN_DELETE_GROUP",
                            "ADMIN_CREATE_GROUP",
                            "ADMIN_UPDATE_GROUP",
                            "ADMIN_VIEW_GROUP",
                        ],
                    },
                    {
                        displayName: "User Approvals",
                        route: "admin/approvals",
                        permissions: ["ADMIN_APPROVE_USER", "ADMIN_DENY_USER"],
                    },
                    {
                        displayName: "Parametric",
                        route: "admin/parametric",
                        permissions: ["ADMIN_APPROVE_USER", "ADMIN_DENY_USER"],
                    },
                ],
            },
            {
                moduleCode: "RESOURCELIBRARY",
                route: "/admin/resources",
                displayName: "",
                useExternalRoute: true,
                icon: "subscriptions",
                isCollapsed: true,
                permissions: resourceLibraryPermissions.GetExploreSectionPermissions(),
                children: [
                    {
                        displayName: "Resources",
                        route: "/admin/resources/manager",
                        permissions: resourceLibraryPermissions.GetResourceModulePermissions(),
                    },
                    {
                        displayName: "Upload Approvals",
                        route: "/admin/resources/upload-approvals",
                        permissions: resourceLibraryPermissions.GetResourceApprovalModulePermissions(),
                    },
                ],
            },
            {
                moduleCode: "ASSESSMENT",
                route: "/admin/assessment",
                displayName: "",
                useExternalRoute: true,
                icon: "assessment",
                isCollapsed: true,
                permissions: assessmentPermissions.GetAdminPermissions(),
                children: [
                    {
                        displayName: "Assessment Sets",
                        route: "/admin/assessment/manager",
                        permissions: [
                            AssessmentPermissions.ADD_ASSESSMENT,
                            AssessmentPermissions.DELETE_ASSESSMENT,
                            AssessmentPermissions.EDIT_ASSESSMENT,
                            AssessmentPermissions.PREVIEW_ASSESSMENT,
                            AssessmentPermissions.PUBLISH_ASSESSMENT,
                        ],
                    },
                    {
                        displayName: "Questions",
                        route: "/admin/assessment/questions",
                        permissions: [
                            AssessmentPermissions.ADD_EDIT_QUESTIONS,
                            AssessmentPermissions.DELETE_QUESTIONS,
                            AssessmentPermissions.IMPORT_QUESTIONS,
                        ],
                    },
                    {
                        displayName: "Import Questions",
                        route: "/admin/assessment/importQuestions",
                        permissions: [AssessmentPermissions.IMPORT_QUESTIONS],
                    },
                ],
            },
            {
                moduleCode: "EVENT",
                route: "/admin/event",
                displayName: "",
                useExternalRoute: true,
                children: null,
                icon: "event",
            },
            {
                moduleCode: "NEWS",
                route: "/admin/news",
                displayName: "",
                useExternalRoute: true,
                children: null,
                icon: "rss_feed",
            },
            {
                moduleCode: "COMPETENCY",
                route: "/admin/competency",
                displayName: "",
                useExternalRoute: true,
                children: null,
                icon: "assignment",
            },
            {
                moduleCode: "LEARNINGPATH",
                route: "/admin/learning-path",
                displayName: "",
                useExternalRoute: true,
                icon: "school",
                isCollapsed: true,
                children: [
                    {
                        route: "/admin/learning-path/goals",
                        displayName: "Goals",
                    },
                    {
                        route: "/admin/learning-path/levels",
                        displayName: "Levels",
                    },
                    {
                        route: "/admin/learning-path/modules",
                        displayName: "Modules",
                    },
                    {
                        route: "/admin/learning-path/paths",
                        displayName: "Paths",
                    },
                ],
            },
        ];

        this.navigationLinks = this.tenantNavResolutionService.getEnabledModules(
            this.navMenuItems
        );
        this.reportsLinks = this.tenantNavResolutionService.getEnabledReports(
            this.navMenuItems
        );

        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // TODO: declare it globally
                window["closeSidebar"]();
            }
        });
    }

    toggleMenuItem(menuItem) {
        this.navigationLinks.forEach((x) => {
            if (x.moduleCode == menuItem.moduleCode) {
                x.isCollapsed = !x.isCollapsed;
            } else {
                x.isCollapsed = true;
            }
        });

        if (menuItem.moduleCode == this.reportsLinks.moduleCode) {
            this.reportsLinks.isCollapsed = !this.reportsLinks.isCollapsed;
        } else {
            this.reportsLinks.isCollapsed = true;
        }
    }

    toggleSearch() {
        console.log("toggled");
    }

    ngAfterViewInit() {
        // TODO: declare it globally
        window["loadSidebar"]();
    }
}
