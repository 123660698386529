import { AppConfigService } from "./services/app-config/app-config.service";
import { PermissionsService } from "./services/permissions/permissions.service";
import { AppConfig } from "./vos/app-config/app-config";
import { Constants } from "./shared/shared.constants";
import { OAuthService } from "angular-oauth2-oidc";
import { StorageService } from "ngx-webstorage-service";

export function initializeApp(appConfig: AppConfigService, permissionService: PermissionsService, oauthService:OAuthService, storage: StorageService) {
    return () => appConfig.loadSettings()
      .then(config => {
          if(oauthService.hasValidAccessToken()){
          var cfg = config as AppConfig
          if (cfg != null) {
            permissionService.list(cfg.tenantConfig.permissionService.serviceUrl)
              .subscribe(permissions => {
                if (permissions != null) {
                  permissions = permissions.filter(x => (x != null) && (x != ''));
                  storage.set(Constants.PERMISSIONS_STORAGE_KEY, JSON.stringify(permissions));
                }
              });
          }
        }
        return config;
      });
  }
