import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { SynapzeCXGridRowComponent } from './synapze-cx-grid-row.component';


@Component({
  selector: 'synapze-cx-grid',
  templateUrl: './synapze-cx-grid.component.html',
  styleUrls: ['./synapze-cx-grid.component.css']
})
export class SynapzeCXGridComponent {

  @Input() public tabluarFormat: boolean = true;
  @Input() public items: any[] = [];
  @Input() public columns: any[] = [];
  @Input() public rowDefs: TemplateRef<any>;
  @Input() public columnDefs: TemplateRef<any>;
  @Input() public emptyListMessage: string;
  @Input() public scrollWindow: boolean = true;
  @Output() onScroll: EventEmitter<any> = new EventEmitter();
  onGridScroll() {
    //console.log('scrolled!!');
    this.onScroll.emit();
  }
}
