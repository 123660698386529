export class ResourceLibraryPermissions {
  static readonly UNPUBLISH_RESOURCE: string = 'RESOURCELIBRARY_UNPUBLISH_RESOURCE';
  static readonly ADD_EDIT_RESOURCE: string = 'RESOURCELIBRARY_ADD_EDIT_RESOURCE';
  static readonly DELETE_RESOURCE: string = 'RESOURCELIBRARY_DELETE_RESOURCE';
  static readonly PUBLISH_RESOURCE: string = 'RESOURCELIBRARY_PUBLISH_RESOURCE';
  static readonly ADD_EDIT_ASSET: string = 'RESOURCELIBRARY_ADD_EDIT_ASSET';
  static readonly DELETE_ASSET: string = 'RESOURCELIBRARY_DELETE_ASSET';
  static readonly EXPORT_REPORTS: string = 'RESOURCELIBRARY_EXPORT_REPORTS';
  static readonly VIEW_REPORTS: string = 'RESOURCELIBRARY_VIEW_REPORTS';
  static readonly ADD_VIDEO: string = 'RESOURCELIBRARY_ADD_VIDEO';
  static readonly EDIT_ASSET_FILE: string = 'RESOURCELIBRARY_EDIT_ASSET_FILE';
  static readonly EDIT_VIDEO: string = 'RESOURCELIBRARY_EDIT_VIDEO';
  static readonly ADD_DOCUMENT: string = 'RESOURCELIBRARY_ADD_DOCUMENT';
  static readonly EDIT_DOCUMENT: string = 'RESOURCELIBRARY_EDIT_DOCUMENT';
  static readonly ADD_COURSE: string = 'RESOURCELIBRARY_ADD_COURSE';
  static readonly EDIT_COURSE: string = 'RESOURCELIBRARY_EDIT_COURSE';
  static readonly ADD_WEBINAR: string = 'RESOURCELIBRARY_ADD_WEBINAR';
  static readonly EDIT_WEBINAR: string = 'RESOURCELIBRARY_EDIT_WEBINAR';
  static readonly ADD_PODCAST: string = 'RESOURCELIBRARY_ADD_PODCAST';
  static readonly VIEW_RECOURCES_AND_ASSETS: string = 'RESOURCELIBRARY_VIEW_RECOURCES_AND_ASSETS';
  static readonly EDIT_PODCAST: string = 'RESOURCELIBRARY_EDIT_PODCAST';
  static readonly USER_UPLOAD: string = 'RESOURCELIBRARY_USER_UPLOAD';
  static readonly USER_APPROVER: string = 'RESOURCELIBRARY_USER_APPROVER';
  static readonly USER_PUBLISHER: string = 'RESOURCELIBRARY_USER_PUBLISHER';
  static readonly APPROVE_ASSET: string = 'RESOURCELIBRARY_APPROVE_ASSET';

  constructor() {
  }

  public  GetExploreSectionPermissions(): string[]
  {
    const permissions =[];
    permissions.push(ResourceLibraryPermissions.UNPUBLISH_RESOURCE);
    permissions.push(ResourceLibraryPermissions.ADD_EDIT_RESOURCE);
    permissions.push(ResourceLibraryPermissions.DELETE_RESOURCE);
    permissions.push(ResourceLibraryPermissions.PUBLISH_RESOURCE);
    permissions.push(ResourceLibraryPermissions.ADD_EDIT_ASSET);
    permissions.push(ResourceLibraryPermissions.DELETE_ASSET);
    permissions.push(ResourceLibraryPermissions.VIEW_RECOURCES_AND_ASSETS);
    permissions.push(ResourceLibraryPermissions.USER_APPROVER);
    permissions.push(ResourceLibraryPermissions.USER_PUBLISHER);
    return permissions;
  }
  public  GetResourceModulePermissions(): string[]
  {
    const permissions =[];
    permissions.push(ResourceLibraryPermissions.UNPUBLISH_RESOURCE);
    permissions.push(ResourceLibraryPermissions.ADD_EDIT_RESOURCE);
    permissions.push(ResourceLibraryPermissions.DELETE_RESOURCE);
    permissions.push(ResourceLibraryPermissions.PUBLISH_RESOURCE);
    permissions.push(ResourceLibraryPermissions.ADD_EDIT_ASSET);
    permissions.push(ResourceLibraryPermissions.DELETE_ASSET);
    permissions.push(ResourceLibraryPermissions.VIEW_RECOURCES_AND_ASSETS);
    return permissions;
  }
  public  GetResourceApprovalModulePermissions(): string[]
  {
    const permissions =[];
    permissions.push(ResourceLibraryPermissions.USER_APPROVER);
    permissions.push(ResourceLibraryPermissions.USER_PUBLISHER);
    return permissions;
  }

  public GetReportPermissions(): string[] {
    const permissions = [];
    permissions.push(ResourceLibraryPermissions.EXPORT_REPORTS);
    permissions.push(ResourceLibraryPermissions.VIEW_REPORTS);
    return permissions;
  }

  public GetApprovePublishPermissions(): string[] {
    const permissions = [];
    permissions.push(ResourceLibraryPermissions.USER_APPROVER);
    permissions.push(ResourceLibraryPermissions.USER_PUBLISHER);
    return permissions;
  }
}
