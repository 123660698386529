import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import {
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CoreModule } from "./core/core.module";
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { APP_INITIALIZER } from "@angular/core";
import { CollapseModule } from "ngx-bootstrap/collapse";

import { OAuthModule, OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { InfiniteScrollModule } from "ngx-infinite-scroll";

// Import 3rd party components
import { TabsModule } from "ngx-bootstrap/tabs";
import { PermissionsService } from "./services/permissions/permissions.service";
import { ModulePermissions } from "./models/appModules.permissions";
import { AlertModule } from "ngx-bootstrap/alert";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
// import { AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule } from '@coreui/angular';
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { DefaultLayoutComponent } from "./containers";
import { AuthGuard } from "./auth/auth.guard";
import { CustomHttpInterceptorService } from "./services/custom-http-interceptor.service";
import { AppConfigService } from "./services/app-config/app-config.service";
import { SharedModule } from "./shared/shared.module";

import {
    TranslateModule,
    TranslateLoader,
    TranslatePipe,
    TranslateService,
    MissingTranslationHandler,
    TranslateParser,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { RolesService } from "src/app/services/roles/roles.service";
import { UsersService } from "./services/users/users.service";
import { TenantModuleResolutionService } from "./services/tenant/tenant-navigation-resolution-service";

import { BaseAppModule } from "src/app/app.base.module";

import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";

import {
    ToastrModule,
    ToastNoAnimation,
    ToastNoAnimationModule,
} from "ngx-toastr";
import { NgSelectModule } from "@ng-select/ng-select";
import { AppConfig } from "./vos/app-config/app-config";

import { SynapzeCxOAuthserviceService } from "./auth/synapze-cx-oauthservice.service";

import { RoleGuardService } from "src/app/views/roles/roles.guard";
import { UserManagementGuard } from "./views/users/user-management.guard";
import { AccessDeniedComponent } from "./views/error/AccessDenied.component";
import {
    LocalizationLoader,
    MyMissingTranslationHandler,
    CustomTranslateParser,
} from "src/app/services/localization-loader.service";
import { initializeApp } from "./app.initializer";
import { Angulartics2Module } from "angulartics2";
import { GroupsGuard } from "./views/groups/groups.guard";
import { GroupsService } from "./services/groups/groups.service";
import {
    CORE_SESSION_STORAGE,
    CORE_LOCAL_STORAGE,
    OAuthStorageService,
} from "./services/storage/storage.service";
import { SESSION_STORAGE, LOCAL_STORAGE } from "ngx-webstorage-service";
import { TagManagerService } from "./services/tagManager/tagManagerService";
import { ParametricGuardService } from "./views/parametric/parametric.guard";

export function HttpLoaderFactory(
    http: HttpClient,
    appConfigService: AppConfigService
) {
    //return new TranslateHttpLoader(http, "/public/lang-files/", "-lang.json");
    return new LocalizationLoader(http, "main", "admin", appConfigService);
}

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        P404Component,
        P500Component,
        AccessDeniedComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        SharedModule,
        BrowserModule,
        DropdownModule,
        MultiSelectModule,
        AppRoutingModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [
                    "https://localhost:44333/api/",
                    "http://localhost:5000/api/",
                ],
                sendAccessToken: true,
            },
        }),
        // CoreModule.forRoot(),
        CollapseModule.forRoot(),
        // AppAsideModule,
        // AppBreadcrumbModule.forRoot(),
        // AppFooterModule,
        // AppHeaderModule,
        // AppSidebarModule,
        PerfectScrollbarModule,
        TabsModule.forRoot(),
        AlertModule.forRoot(),
        InfiniteScrollModule,
        NgSelectModule,
        Angulartics2Module.forRoot(),
        ToastNoAnimationModule.forRoot({
            timeOut: 3000,
            positionClass: "toast-top-right",
            preventDuplicates: true,
            progressBar: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, AppConfigService],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler,
            },
            parser: {
                provide: TranslateParser,
                useClass: CustomTranslateParser,
            },
        }),
        SweetAlert2Module.forRoot({
            buttonsStyling: false,
            customClass: "modal-content",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn",
        }),
    ],
    entryComponents: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [
        AppConfigService,
        PermissionsService,
        { provide: CORE_LOCAL_STORAGE, useExisting: LOCAL_STORAGE },
        { provide: CORE_SESSION_STORAGE, useExisting: SESSION_STORAGE },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptorService,
            multi: true,
        },
        { provide: OAuthStorage, useClass: OAuthStorageService },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [
                AppConfigService,
                PermissionsService,
                OAuthService,
                CORE_SESSION_STORAGE,
            ],
            multi: true,
        },
        SynapzeCxOAuthserviceService,
        OAuthService,
        AuthGuard,
        RoleGuardService,
        UserManagementGuard,
        ModulePermissions,
        RolesService,
        UsersService,
        GroupsService,
        TenantModuleResolutionService,
        GroupsGuard,
        ParametricGuardService,
        TagManagerService,
    ],
    bootstrap: [AppComponent],
})

/**
 * Main app module. Import your submodules here.
 */
export class AppModule extends BaseAppModule {
    //constructor(private translate: TranslateService, private usersService: UsersService) {
    //  super();
    //  this.setTranslationLocale(translate, usersService);
    //}
}
