export class Constants {
  static readonly PERMISSIONS_STORAGE_KEY = 'permissions';
  static readonly TENANT_MODULES = 'TENANT_MODULES';
  static readonly ADMIN_MODULE_PERMISSIONS = 'ADMIN_MODULES_PERMISSIONS';
  static readonly ADMIN_MODULE_CODE = "ADMIN";
  static readonly TENANT_METADATA = 'tenantMetadata';
  static readonly USER_BROWSER_LANGUAGE = "USER_BROWSER_LANGUAGE";
  static readonly LOGOUT_IN_PROGRESS = "LOGOUT_IN_PROGRESS";
  static readonly LOGIN_IN_PROGRESS = "LOGIN_IN_PROGRESS";
}
