export class dataGridModel<T> {
  constructor() {
    this.sortColumns = [];
    this.pageSize = 15;
    this.pageNo = 1;

  }
  pageNo?: number;
  pageSize?: number;
  searchParameters: T;
  sortColumns?: sortModel[];
}


export class sortModel {
  colId?: string;
  sort?: string;
}

