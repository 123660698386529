<form [formGroup]="editRoleSelectorForm">
  <div class="job-roles-selector">
    <div class="row">
      <div class="col-md-6">
        <div id="jobroleComponent" *ngIf="!readOnly" [ngClass]="{'editRole':''}[source]">
          <div class="dropdown job-dropdown" data-name='statuses'>
            <label for="jobRoleSelector" [style.display]="'none'">Select Job Roles</label>
            <ng-select [items]="jobDesignations"
              [multiple]="true"
              bindLabel="name"
              groupBy="category"
              [selectableGroup]="true"
              [selectableGroupAsModel]="false"
              [closeOnSelect]="false"
              bindValue="id"
              formControlName="JobDesignations"
              placeholder={{placeholder}}
              (change)="onChange($event)"
              labelForId="jobRoleSelector" title="Select Job Roles">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="checkbox-container-list">
                  <label for="item-{{index}}" [style.display]="'none'">{{item.category | uppercase}}</label>
                  <input id="item-{{index}}" class="custom-checkbox-list" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" title="item$.label" />&nbsp;<strong>{{item.category | uppercase}}</strong>
                  <span class="checkmark-list"></span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="checkbox-container-list">
                  <label for="item-{{index}}" [style.display]="'none'">{{item.name}}</label>
                  <input id="item-{{index}}" class="custom-checkbox-list" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" title="item$.label" /> {{item.name}}
                  <span class="checkmark-list"></span>
                </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div *ngIf="onlyShowTotalNoOfItemsSelected">
                  {{'admin-common-dropdown_items_selected' | translate : '{ itemsLength: ' + items.length + ', defaultText: "\{\{itemsLength\}\} items selected"}'}}
                </div>
              </ng-template>
            </ng-select>
          </div>      
        </div>
      </div>
    </div>
    <ng-container *ngIf="showselection==true">
    <div class="row">
        <div class="col-md-12 m-t-20">
          <div class="role-item" *ngFor="let jd of jobRoleCodes;let i = index ">{{jd | jobRoleName | async}} <button (click)="deleteRole(i, 'job');" class="remove-tags"></button></div>
          <div class="role-item" *ngFor="let jd of systemRoleCodes;let i = index ">{{jd | tenantRoleName | async}} <button (click)="deleteRole(i, 'system');" class="remove-tags"></button></div>
          <div class="role-item" *ngFor="let jd of customRoleCodes;let i = index ">{{jd | tenantRoleName | async}} <button (click)="deleteRole(i, 'custom');" class="remove-tags"></button></div>
        </div>
      </div>
    </ng-container>
    
  </div>
</form>

