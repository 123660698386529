<form [formGroup]="dropDownForm">

  <ng-container *ngIf="showLabel==true; else elseTemplate">
    <label for="dropdown" [style.display]="'inline'">{{label}}</label>
  </ng-container>
  <ng-template #elseTemplate>
    <label for="dropdown" [style.display]="'none'">{{label}}</label>
  </ng-template>
  <ng-container *ngIf="enableMultiSelection==true; else noMultiSelection">
    <p-multiSelect
      formControlName="dropdownControl"
      [options]="_dropdownitems"
      (onChange)="onChange()"
      title="{{ _title }}"
      class="multipleDropdown"
    >
    </p-multiSelect>
  </ng-container>
  <ng-template #noMultiSelection>
    <p-dropdown
      formControlName="dropdownControl"
      [options]="_dropdownitems"
      placeholder="{{ _placeholder }}"
      (onChange)="onChange()"
      title="{{ _title }}"
      class="simpleDropdown"
    >
    </p-dropdown>
  </ng-template>


</form>
