<form [formGroup]="editLocationForm">
  <div>
    <div class="row m-t-20" *ngIf="!readOnly">
      <div class="col-md-6">
        <label class="m-b-10" for="locationType">
          {{'admin-common-searchlocationby_label' | translate: { defaultText : 'Search By'} }}
        </label>
        <synapze-cx-simple-dropdown class="dropdown-no-lable" [enableMultiSelection]="false" [readOnly]="_readOnly" formControlName="locationtype"
          placeholder="{{'admin-common-searchlocationby_placeholder' | translate: '{ defaultText : \'Search Location By\'}' }}"
          [(dropdownitems)]="tenantLocationTypes">
        </synapze-cx-simple-dropdown>
      </div>
      <div class="col-md-4">
        <label class="m-b-10" for="locationName">{{getLocationTypeLabel(editLocationForm.controls['locationtype'].value)}}</label>
        <synapze-cx-simple-dropdown class="dropdown-no-lable" [enableMultiSelection]="false" [readOnly]="_readOnly" formControlName="locationname"
          placeholder="{{'admin-common-selectalocation_placeholder' | translate: { defaultText : 'Select a Location'} }}"
          [(dropdownitems)]="tenantLocationTypeNames">
        </synapze-cx-simple-dropdown>
      </div>
      <div class="col-md-4">
        <button type="button" class="btn btn-primary add-tag-btn" [disabled]="editLocationForm.controls['locationname'].value == ''" (click)="addLocation()">
          {{'admin-common-addbutton_label' | translate: { defaultText : 'Add'} }}
        </button>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-md-12">
        <div class="role-item" *ngFor="let location of locations;let i = index">
          {{location | locationName | async}}
          <button class="remove-tags" (click)="deleteLocation(i)"></button>
        </div>
      </div>
    </div>
  </div>
</form>
