import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { AppConfig, TenantModule } from 'src/app/vos/app-config/app-config';

import { when } from 'q';
import { Router } from '@angular/router';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class AppConfigServiceConfig {
  uri = '';
}



/**
 * Declaration of config class
 */
export class AppSettings {
  //Your properties here
  readonly production: boolean;
  readonly configUrl: string;
}

/**
 * Global variable containing actual config to use. Initialised via ajax call
 */
export let APP_CONFIG: AppSettings;


@Injectable({
  providedIn: 'root',
})
/**
 * Service class.
 */
export class AppConfigService {

  private appConfig: AppConfig;

  private isLoaded: boolean;

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  //private _uri = environment.configUrl;


  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   * @param {AppConfigsServiceConfig} config
   */
  constructor(private http: HttpClient, @Optional() config: AppConfigServiceConfig) {
    //if (config) {
    //  this._uri = config.uri;
    //}
  }

  //load(callback: (config: AppConfig) => any) {
  //  this.http.get<AppConfig>(this._uri).subscribe(config => {
  //    this.appConfig = config;
  //    callback(config);
  //  });
  //}

  getOrigin(): string {

    if (!window.location.origin) {
      return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    else {
      return window.location.origin;
    }
  }

  public loadSettings() {
    return new Promise((resolve, reject) => {

      this.http.get('/assets/appsettings.json').subscribe((envResponse: any) => {
        let t = new AppSettings();
        //Modify envResponse here if needed (e.g. to ajust parameters for https,...)
        APP_CONFIG = Object.assign(t, envResponse);

        const params = new HttpParams()
          .set('tenantUrl', this.getOrigin())
          .set('urlFor', "ADMINSPA");


        this.http.get<AppConfig>(APP_CONFIG.configUrl, { params }).subscribe(config => {
          this.appConfig = config;
          resolve(this.appConfig);
        });
      },
        (error) => {
          reject(true);
          return Observable.throw('Server error');
        });

    });

  }


  //loadSettings(): Promise<any> {
  //  const params = new HttpParams()
  //    .set('tenantUrl', this.getOrigin())
  //    .set('urlFor', "ADMINSPA");
      
  //  const promise = this.http.get<AppConfig>(this._uri,{params}).toPromise().then(config => {
  //    this.appConfig = config;
  //    return config
  //  });
  //  return promise;
  //}

  /**
   * Pulls a single AppConfig object.
   * @param {number | string} id to retrieve.
   * @returns {Observable<AppConfig>}
   */
  get(): AppConfig {
    return this.appConfig;
  }

  getModule(moduleName:string):TenantModule
  {
    let module =  this.appConfig.tenantConfig.modules.filter(x=> x.moduleName == moduleName);
    if(module !=null || module != undefined){
      return module[0];    
     }
  }

  getResourceModuleApiUrl():string
  {
    let resModule =  this.getModule(this.appConfig.tenantConfig.resourceModuleName);
    if(resModule !=null)
    {
      return resModule.serviceUrl;
    }
    return "";
  }

  getResourceModuleSpaUrl():string
  {
    let resModule =  this.getModule(this.appConfig.tenantConfig.resourceModuleName);
    if(resModule !=null)
    {
      return resModule.spaUrl;
    }
    return "";
  }

  getUserAdminModuleApiUrl():string
  {
    let resModule =  this.getModule(this.appConfig.tenantConfig.userAdminModuleName);
    if(resModule !=null)
    {
      return resModule.serviceUrl;
    }
    return "";
  }

  getUserAdminModuleSpaUrl():string
  {
    let resModule =  this.getModule(this.appConfig.tenantConfig.userAdminModuleName);
    if(resModule !=null)
    {
      return resModule.spaUrl;
    }
    return "";
  }

  getTestModuleApiUrl():string
  {
    let resModule =  this.getModule(this.appConfig.tenantConfig.testModuleName);
    if(resModule !=null)
    {
      return resModule.serviceUrl;
    }
    return "";
  }

}
