<app-header
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo.png', width: 89, height: 25, alt: 'SynapzeCX Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/sprite.png', width: 30, height: 30, alt: 'CoreUI Logo'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="'lg'">
  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Admin Dashboard</a>
    </li>
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
    </li>
    <!-- <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-list"></i></a>
    </li> -->
    <user-profile-menu></user-profile-menu>
  </ul>
</app-header>
<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">Access Denied</h1>
        </div>        
        <h4 class="pt-3">You do not have permissions to access this page.</h4>
      </div>
    </div>
  </div>
</div>
