<div class="alert alert-danger" *ngIf="errors && errors.length > 0">
  {{'core-common-error_summar_header' | translate : '{ defaultText: "Please correct the errors."}'}}
</div>
<ul class="alert alert-danger" *ngIf="useTranslation == false && filteredErrors && filteredErrors.length > 0">
  <li *ngFor="let err of filteredErrors">
    <span class="error-header" *ngIf="err.errorSeverity == 1">Warning:</span>
    <span class="error-header" *ngIf="err.errorSeverity == 0">Error:</span>
    <span innerHTML="{{err.errorDescription}}"></span>
  </li>
</ul>

<ul class="alert alert-danger" *ngIf="useTranslation == true && filteredErrors && filteredErrors.length > 0">
  <li *ngFor="let err of filteredErrors">
    <span class="error-header" *ngIf="err.errorSeverity == 1">Warning:</span>
    <span class="error-header" *ngIf="err.errorSeverity == 0">Error:</span>
    <span innerHTML="{{err.errorCode | translate: err.errorParameters }}"></span>
  </li>
</ul>
