import { NgModule, ModuleWithProviders  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';


import { SynapzeCXGridComponent } from './synapze-cx-grid/synapze-cx-grid.component';
import { SynapzeCXGridRowComponent } from './synapze-cx-grid/synapze-cx-grid-row.component';
import { SynapzeCXGridColumnComponent } from './synapze-cx-grid/synapze-cx-grid-column.component';
import { ValidationSummaryComponent } from './validation-errors/validation-summary.component';
import { ValidationErrorComponent } from './validation-errors/validation-error.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { PermissionBlockComponent } from './/permission-block/permission-block.component';
import { SynapzeCxModalComponent } from './synapze-cx-modal/synapze-cx-modal.component';
import { SynapzeAppMenuComponent } from './synapze-app-menu/synapze-app-menu.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { SynapzeToastrService } from './toastr/toastr.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MediaService } from 'src/app/services/media/media.service';
import { EditorModule } from 'primeng/editor';
import { SynapzeCxRichTextComponent } from './synapze-cx-rich-text/synapze-cx-rich-text.component';
import { PeAutoscrollFixDirective } from './synapze-cx-rich-text/p-editor-model.directive';
import { SynapzeCxCalendarComponent } from './synapze-cx-calendar/synapze-cx-calendar.component'
import { SynapzeAppFooterComponent } from './synapze-app-footer/synapze-app-footer.component'
import { SynapzeCxFormControlComponent } from './synapze-cx-form-control/synapze-cx-form-control.component'
import { MissingTranslationHandler, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { MyMissingTranslationHandler, CustomTranslateParser } from 'src/app/services/localization-loader.service';
import { SynapzeCxSimpleDropDownComponent } from './synapze-cx-simple-dropdown/synapze-cx-simple-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContenteditableModule } from '@ng-stack/contenteditable';

import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule
} from 'ngx-toastr';
import { TenantLocationSelectorComponent } from './tenant-location-selector/tenant-location-selector.component';
import { LocationNamePipe } from 'src/app/pipes/location-name-pipe';
import { JobRoleTenantRoleSelectorComponent } from './jobRole-tenantRole-selector/jobRole-tenantRole-selector.component';
import { JobRoleNamePipe } from 'src/app/pipes/job-role-name-pipe';
import { TenantRoleNamePipe } from 'src/app/pipes/tenant-role-name-pipe';
import { JobRoleTenantRoleSelectorModalComponent } from './jobRole-tenantRole-selector/job-role-tenant-role-selector-modal.component';
import { TenantLocationSelectorModalComponent } from './tenant-location-selector/tenant-location-selector-modal.component';
import { ContentLoaderComponent } from './content-loader/content-loader.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InfiniteScrollModule,
    ModalModule.forRoot(),
    SweetAlert2Module,
    RouterModule,
    DropdownModule,
    EditorModule,
    MultiSelectModule,
    NgSelectModule,
    CalendarModule,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      parser: { provide: TranslateParser, useClass: CustomTranslateParser },
      isolate: true,

    }),
    ContenteditableModule,
    ConfirmDialogModule
  ],
  declarations: [
    SynapzeCXGridComponent,
    SynapzeCXGridRowComponent,
    SynapzeCXGridColumnComponent,
    ValidationSummaryComponent,
    ValidationErrorComponent,
    ConfirmationModalComponent,
    PermissionBlockComponent,
    SynapzeCxModalComponent,
    SynapzeAppMenuComponent,
    UserProfileComponent,
    SynapzeCxRichTextComponent,
    PeAutoscrollFixDirective,
    SynapzeCxCalendarComponent,
    SynapzeAppFooterComponent,
    SynapzeCxFormControlComponent,
    SynapzeCxSimpleDropDownComponent,
    TenantLocationSelectorComponent,
    LocationNamePipe,
    JobRoleTenantRoleSelectorComponent,
    JobRoleNamePipe,
    TenantRoleNamePipe,
    JobRoleTenantRoleSelectorModalComponent,
    TenantLocationSelectorModalComponent,
    ContentLoaderComponent,
  ],
  exports: [
    SynapzeCXGridComponent,
    SynapzeCXGridRowComponent,
    SynapzeCXGridColumnComponent,
    ValidationSummaryComponent,
    ValidationErrorComponent,
    ConfirmationModalComponent,
    PermissionBlockComponent,
    SynapzeCxModalComponent,
    SynapzeAppMenuComponent,
    SynapzeCxRichTextComponent,
    SynapzeCxCalendarComponent,
    SynapzeAppFooterComponent,
    SynapzeCxFormControlComponent,
    SynapzeCxSimpleDropDownComponent,
    ContenteditableModule,
    TenantLocationSelectorComponent,
    LocationNamePipe,
    JobRoleTenantRoleSelectorComponent,
    JobRoleNamePipe,
    TenantRoleNamePipe,
    JobRoleTenantRoleSelectorModalComponent,
    TenantLocationSelectorModalComponent,
    ContentLoaderComponent,
  ],
  entryComponents: [
    //SynapzeCXGridComponent
  ],
  providers: [
    MediaService,
    ConfirmationService
  ]
})
export class SynapzeComponentsModule {
  static forRoot(): ModuleWithProviders<SynapzeComponentsModule> {
    return {
      ngModule: SynapzeComponentsModule,
      providers: [SynapzeToastrService]
    }
  }
}
