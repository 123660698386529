<app-synapze-app-menu
    [adminView]="true"
    [containerClass]="'container-fluid'"
></app-synapze-app-menu>
<div
    data-responsive-width="992px"
    class="mdk-drawer-layout js-mdk-drawer-layout"
    data-push
    data-domfactory-upgraded="mdk-drawer-layout"
>
    <section class="mdk-drawer-layout__content page">
        <div class="container-fluid page__container">
            <router-outlet></router-outlet>
        </div>
    </section>

    <section
        class="mdk-drawer js-mdk-drawer"
        id="menuAdmin"
        data-align="start"
        data-position="left"
    >
        <div class="mdk-drawer__scrim"></div>
        <div class="mdk-drawer__content">
            <div
                class="sidebar sidebar-left sidebar-dark bg-dark o-hidden"
                data-perfect-scrollbar
            >
                <div class="sidebar-p-y">
                    <div class="sidebar-heading">
                        {{
                            "core-common-admin_leftnav_manage"
                                | translate
                                    : { defaultText: "MANAGE AND CREATE" }
                        }}
                    </div>
                    <ul class="sidebar-menu">
                        <li
                            class="sidebar-menu-item"
                            [ngClass]="{
                                active:
                                    router.url
                                        .toString()
                                        .indexOf('/admin/reports') > -1
                            }"
                        >
                            <a
                                class="sidebar-menu-button"
                                *ngIf="
                                    reportsLinks.children === null &&
                                    !reportsLinks.children.length
                                "
                            >
                                <span
                                    class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                                    >{{ reportsLinks.icon }}</span
                                >
                                {{
                                    "core-common-admin_leftbar_" +
                                        reportsLinks.displayName
                                        | lowercase
                                        | translate
                                            : {
                                                  defaultText:
                                                      reportsLinks.displayName
                                              }
                                }}
                            </a>
                            <a
                                [class]="
                                    'sidebar-menu-button sidebar-js-collapse ' +
                                    !reportsLinks.isCollapsed
                                "
                                *ngIf="
                                    reportsLinks != null &&
                                    reportsLinks.useExternalRoute == false &&
                                    reportsLinks.children != null &&
                                    reportsLinks.children.length > 0
                                "
                                href="javascript: void(0);"
                                (click)="toggleMenuItem(reportsLinks)"
                                [attr.aria-expanded]="!reportsLinks.isCollapsed"
                                aria-controls="collapseBasic"
                            >
                                <span
                                    class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                                    >{{ reportsLinks.icon }}</span
                                >
                                {{
                                    "core-common-admin_leftbar_" +
                                        reportsLinks.displayName
                                        | lowercase
                                        | translate
                                            : {
                                                  defaultText:
                                                      reportsLinks.displayName
                                              }
                                }}
                                <span class="ml-auto material-icons"
                                    >chevron_right</span
                                >
                            </a>
                            <ul
                                class="sidebar-submenu sm-indent"
                                id="{{ reportsLinks.displayName }}"
                                [collapse]="reportsLinks.isCollapsed"
                            >
                                <li
                                    class="sidebar-menu-item"
                                    *ngFor="let item of reportsLinks.children"
                                    [ngClass]="{
                                        active:
                                            item.route &&
                                            router.url
                                                .toString()
                                                .indexOf(item.route) > -1
                                    }"
                                >
                                    <a
                                        id="{{ item.displayName }}"
                                        class="sidebar-menu-button"
                                        [attr.data-url]="item.route"
                                        [ngClass]="{
                                            active: router.url == item.route
                                        }"
                                        [href]="[item.route]"
                                    >
                                        <span class="sidebar-menu-text">
                                            {{
                                                "core-common-admin_leftbar_" +
                                                    item.displayName
                                                    | lowercase
                                                    | translate
                                                        : {
                                                              defaultText:
                                                                  item.displayName
                                                          }
                                            }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li
                            class="sidebar-menu-item"
                            [attr.data-url]="item.route"
                            [attr.data-item]="item.route"
                            *ngFor="let item of navigationLinks"
                            [ngClass]="{
                                active:
                                    item.route &&
                                    router.url.toString().indexOf(item.route) >
                                        -1
                            }"
                        >
                            <a
                                class="sidebar-menu-button"
                                *ngIf="
                                    item.useExternalRoute == false &&
                                    !item.children
                                "
                                [routerLink]="[item.route]"
                            >
                                <span
                                    class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                                    >{{ item.icon }}</span
                                >
                                {{ item.displayName }}
                            </a>

                            <a
                                class="sidebar-menu-button"
                                *ngIf="
                                    item.useExternalRoute == true &&
                                    !item.children
                                "
                                [href]="[item.route]"
                            >
                                <span
                                    class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                                    >{{ item.icon }}</span
                                >
                                {{ item.displayName }}
                            </a>

                            <a
                                [class]="
                                    'sidebar-menu-button ' + !item.isCollapsed
                                "
                                *ngIf="
                                    item.children && item.children.length > 0
                                "
                                (click)="toggleMenuItem(item)"
                                [attr.aria-expanded]="!item.isCollapsed"
                                aria-controls="collapseBasic"
                                href="javascript: void(0);"
                            >
                                <span
                                    class="sidebar-menu-icon sidebar-menu-icon--left material-icons"
                                    >{{ item.icon }}</span
                                >
                                {{
                                    "core-common-admin_leftbar_" +
                                        item.displayName
                                        | lowercase
                                        | translate
                                            : { defaultText: item.displayName }
                                }}
                                <span class="ml-auto material-icons"
                                    >chevron_right</span
                                >
                            </a>
                            <ul
                                class="sidebar-submenu sm-indent"
                                id="{{ item.displayName }}"
                                *ngIf="
                                    item.children != null &&
                                    item.children.length > 0
                                "
                                [collapse]="item.isCollapsed"
                            >
                                <li
                                    class="sidebar-menu-item"
                                    *ngFor="let child of item.children"
                                    [attr.data-url]="child.route"
                                    [ngClass]="{
                                        active:
                                            child.route &&
                                            router.url
                                                .toString()
                                                .indexOf(child.route) > -1
                                    }"
                                >
                                    <a
                                        class="sidebar-menu-button"
                                        [routerLink]="'/' + child.route"
                                        *ngIf="item.useExternalRoute == false"
                                    >
                                        <span class="sidebar-menu-text">
                                            {{
                                                "core-common-admin_leftbar_" +
                                                    child.displayName
                                                    | lowercase
                                                    | translate
                                                        : {
                                                              defaultText:
                                                                  child.displayName
                                                          }
                                            }}
                                        </span>
                                    </a>
                                    <a
                                        class="sidebar-menu-button"
                                        [href]="child.route"
                                        *ngIf="item.useExternalRoute == true"
                                    >
                                        <span class="sidebar-menu-text">
                                            {{
                                                "core-common-admin_leftbar_" +
                                                    child.displayName
                                                    | lowercase
                                                    | translate
                                                        : {
                                                              defaultText:
                                                                  child.displayName
                                                          }
                                            }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>
