import { Permission } from "../vos/permission/permission";
import { Injectable } from "@angular/core";

@Injectable()
export class ModulePermissions {
  static readonly Admin: string[] = ['Admin.User.Read','Admin.User.Write']
  readonly UPDATE_ROLE: string = "ADMIN_UPDATE_ROLE";
  readonly UPDATE_SYSTEM_ROLE: string = "ADMIN_UPDATE_SYSTEM_ROLE";
  readonly DELETE_SYSTEM_ROLE: string = "ADMIN_DELETE_SYSTEM_ROLE";
  readonly CREATE_ROLE: string = "ADMIN_CREATE_ROLE";
  readonly CREATE_SYSTEM_ROLE: string = "ADMIN_CREATE_SYSTEM_ROLE";
  readonly DELETE_ROLE: string = "ADMIN_DELETE_ROLE";

  readonly UPDATE_GROUP: string = "ADMIN_UPDATE_GROUP";
  readonly CREATE_GROUP: string = "ADMIN_CREATE_GROUP";
  readonly DELETE_GROUP: string = "ADMIN_DELETE_GROUP";
  readonly VIEW_GROUP: string = "ADMIN_VIEW_GROUP";

  readonly VIEW_USER: string = "ADMIN_VIEW_USER";
  readonly UPDATE_USER: string = "ADMIN_UPDATE_USER";
  readonly ACTIVATE_USER: string = "ADMIN_ACTIVATE_USER";
  readonly DEACTIVATE_USER: string = "ADMIN_DEACTIVATE_USER";
  readonly RESETPWD_USER: string = "ADMIN_RESETPWD_USER";
  static readonly APPROVE_USER: string = "ADMIN_APPROVE_USER";
  static readonly DENY_USER: string = "ADMIN_DENY_USER";

  readonly VIEW_PARAMETRIC: string = "ADMIN_VIEW_PARAMETRIC";
  readonly MODIFY_PARAMETRIC: string = "ADMIN_MODIFY_PARAMETRIC";

  permissions:string[]=[];
  constructor(){
    this.permissions.push(this.UPDATE_ROLE);
    this.permissions.push(this.UPDATE_SYSTEM_ROLE);
    this.permissions.push(this.DELETE_SYSTEM_ROLE);
    this.permissions.push(this.CREATE_ROLE);
    this.permissions.push(this.CREATE_SYSTEM_ROLE);
    this.permissions.push(this.DELETE_ROLE);

    this.permissions.push(this.VIEW_USER);
    this.permissions.push(this.UPDATE_USER);
    this.permissions.push(this.ACTIVATE_USER);
    this.permissions.push(this.DEACTIVATE_USER);
    this.permissions.push(this.RESETPWD_USER);
    this.permissions.push(ModulePermissions.APPROVE_USER);
    this.permissions.push(ModulePermissions.DENY_USER);
    this.permissions.push(this.VIEW_PARAMETRIC);
    this.permissions.push(this.MODIFY_PARAMETRIC);
  }

  public  GetModulePermissions():string[]
  {
    return this.permissions;   
  }
}
