import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonsModule } from 'ngx-bootstrap/buttons'; 
import { TabsModule } from 'ngx-bootstrap/tabs'; 
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MomentModule } from 'ngx-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SynapzeComponentsModule } from 'src/app/components/synapze-shareable-components/synapze-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { MenuModule } from 'primeng/menu';


@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    SynapzeComponentsModule.forRoot(),
    AlertModule.forRoot()
    
    ],
    declarations: [],
    exports: [
      ChartsModule,
      DropdownModule,
      MultiSelectModule,
      ButtonsModule,
      BsDatepickerModule,
      MomentModule,
      ModalModule,
      SynapzeComponentsModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      TabsModule,
      CollapseModule,
      TypeaheadModule,
      AlertModule,
      NgSelectModule,
      MenuModule,
    ]
})

/**
 * Shares common components, directives, and pipes that
 * may not be as application specific.
 *
 * @see https://angular.io/guide/ngmodule#shared-modules
 */
export class SharedModule {
}
