import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppConfigService } from '../../../services/app-config/app-config.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TenantLocale, TenantFooterConfiguration } from 'src/app/models/tenantModel';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { UsersService } from 'src/app/services/users/users.service';
import { DropDownItemModel } from "src/app/models/DropDownItemModel";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'synapze-app-footer',
  templateUrl: './synapze-app-footer.component.html'
})
export class SynapzeAppFooterComponent implements OnInit {

  tenantLocales: TenantLocale[];
  languages: DropDownItemModel[] = [];
  
  items: MenuItem[];
  currentLanguage: string;
  navigationLinks: any[];
  menuConfiguration = {
    autoDisplay: false
  };



  adminUrl: string;
  currentLocale: string;
  mobileVisible = false;
  tenantFooterConfiguration: TenantFooterConfiguration;

  constructor(
    private appConfigService: AppConfigService,
    private userService: UsersService,
    private tenantService: TenantService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {

    this.currentLanguage = this.userService.getUserBrowsingLanguage();
    this.userService.events().subscribe(message => {
      if (message.event == UsersService.CURRENT_USER_FETCHED_EVENT) {
     

        this.currentLanguage = this.userService.getUserBrowsingLanguage();
      }
    });

    var test = false;
    this.userService.events().subscribe(message => {
      if (message.event == UsersService.CURRENT_USER_FETCHED_EVENT) {
        //this.callGTM(this.userService.currentUser());
        if (!test) {
          this.currentLocale = this.userService.currentUser().browsingLanguagePreference;
          this.getLocales();
        }
        test = true;
      }
    });

    var userData = this.userService.currentUser();
    if (userData != null) {
      this.currentLocale = userData.browsingLanguagePreference;
      this.getLocales();
    }
    this.tenantService.getTenantMetadata().subscribe(conf => {
      this.tenantFooterConfiguration = conf.tenantFooterConfiguration;
    })

  }

  getLocales() {
    this.tenantService.getTenantLocales().subscribe(result => {
      this.tenantLocales = result;
      if (result != null) {
        result.forEach(jb => {
          if (jb.localeNames != null) {
            var loc = jb.localeNames.filter(a => a.locale == this.currentLocale);
            loc.forEach(locale => {
              this.languages = [...this.languages, { label: locale.localeName, value: jb.locale }];
            });
          }
          else {
            this.languages = [...this.languages, { label: jb.localeName, value: jb.locale }];
          }
        });
      }

      this.currentLanguage = this.userService.getUserBrowsingLanguage();
    });
  }

  change(val: any) {
    //this.userService.updateUserBrowsingLanguage(val);
    this.userService.updateUserBrowsingLanguage(val).subscribe(s => {
      this.document.location.reload();
    });
  }
}
