import { OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc";
import { Component, Input, OnInit } from "@angular/core";
import { PermissionsService } from "./services/permissions/permissions.service";
import { Constants } from "./shared/shared.constants";
import { AppConfigService } from "./services/app-config/app-config.service";
import { AppConfig, UrlModel } from "./vos/app-config/app-config";
import { NavigationEnd, Router } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { TranslateService } from "@ngx-translate/core";
import { SynapzeCxOAuthserviceService } from "./auth/synapze-cx-oauthservice.service";
import {
    DomSanitizer,
    SafeUrl,
    SafeResourceUrl,
    Title,
} from "@angular/platform-browser";
import { UsersService } from "src/app/services/users/users.service";
import { Angulartics2GoogleTagManager } from "angulartics2/gtm";
import { TagManagerService } from "./services/tagManager/tagManagerService";

@Component({
    selector: "app-root",
    //template: '<router-outlet></router-outlet>',
    //selector: 'app-root',
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
    providers: [PermissionsService, AuthGuard],
    host: {
        class: "mdk-header-layout js-mdk-header-layout",
    },
})

/**
 * Main App Component.
 */
export class AppComponent implements OnInit {
    isLoaded: Boolean;

    /**
     * Shown title of view.
     * @type {string}
     */
    // title = 'SynapseCX';
    cssURLS: SafeResourceUrl[] = [];

    constructor(
        private router: Router,
        translate: TranslateService,
        synapzecxoauthService: SynapzeCxOAuthserviceService,
        private appConfigService: AppConfigService,
        private sanitizer: DomSanitizer,
        private userService: UsersService,
        angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private tagManagerService: TagManagerService,
        titleService: Title
    ) {
        const config = this.appConfigService.get();

        const tenantUrl: UrlModel = config.otherTenantUrls.find(
            (t) => t.url === appConfigService.getOrigin()
        );
        if (tenantUrl) {
            titleService.setTitle(tenantUrl.name);
        }

        if (config != null && config.urlMetaData != null) {
            this.cssURLS = config.urlMetaData.cssPackages.map((m) =>
                this.sanitizer.bypassSecurityTrustResourceUrl(m)
            );
        }
    }

    /**
     * Called part of the component lifecycle. Best first
     * place to start adding your code.
     */
    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                var test = false;
                this.userService.events().subscribe((message) => {
                    if (
                        message.event == UsersService.CURRENT_USER_FETCHED_EVENT
                    ) {
                        //this.callGTM(this.userService.currentUser());
                        if (!test) {
                            this.callGTM(this.userService.currentUser());
                        }
                        test = true;
                    }
                });

                var userData = this.userService.currentUser();
                if (userData != null) this.callGTM(userData);
                return;
            }
        });
    }

    callGTM(userData: any) {
        var config = this.appConfigService.get();
        if (userData != null) {
            var data = {
                URL: this.router.url,
                userCode: userData.userCode,
                locations: userData.locationNames,
                jobTitleCode: userData.jobRoleCode,
                jobTitle: userData.jobRole,
                pageLanguage: userData.browsingLanguagePreference,
            };
        }
        if (config.urlMetaData && config.urlMetaData.gtmSettings) {
            this.tagManagerService.init(
                [config.urlMetaData.gtmSettings.gtm_id],
                config.urlMetaData.gtmSettings.gtm_auth,
                config.urlMetaData.gtmSettings.gtm_preview
            );
            if (data != null && data != undefined) {
                this.tagManagerService.pushCustomEvent(
                    "Page View Router",
                    data
                );
            }
        }
    }
}
