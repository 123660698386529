<form [formGroup]="textForm">
  <p-editor formControlName="description" [p-editor-model]="model"  (onTextChange)="textChange($event)" (onInit)="onEditorInit($event)" [style]="{'height':height}">

      <p-header *ngIf="onlyFontWeight">
          <span class="ql-formats">
              <button class="ql-bold" aria-label="Bold"></button>
              <button class="ql-italic" aria-label="Italic"></button>
              <button class="ql-underline" aria-label="Underline"></button>
              <button class="ql-link" aria-label="Insert Link"></button>
          </span>
      </p-header>

  </p-editor>
</form>
