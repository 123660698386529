<div>
  <ng-content></ng-content>
  <ng-container *ngIf='filteredErrors?.length > 0'>
    <span class="text-danger" *ngFor="let err of filteredErrors">
      <ng-container *ngIf="useTranslation">
        <div>
          <!--{{err.errorCode | translate : '{ defaultText: \'' + removeSingleQuotes(err.errorDescription) + '\'}'}}-->
          {{err.errorCode | translate: err.errorParameters }}
        </div>
      </ng-container>
      <ng-container *ngIf="!useTranslation">
        {{err.errorDescription}}
      </ng-container>
    </span>
  </ng-container>
</div>

