import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BaseResultModel } from 'src/app/models/baseResultModel';
import { MediaBlobModel, MediaModel } from 'src/app/models/media/mediaModel';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';

@Injectable()
/**
 * Service class.
 */
export class MediaService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = '/api/media';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;
  private _sanitizer: DomSanitizer;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService, sanitizer: DomSanitizer) {
    this._appConfigService = appConfigService;
    this._sanitizer = sanitizer;
    if (this._appConfigService) {
      this._uri = `${this._appConfigService.get().tenantConfig.coreService.serviceUrl}` + this._uri;
      //console.log(this._uri);
    }
  }

  /**
   * Pulls a list of modules
   * @returns {Observable<MediaModel>}
   */
  get(id: string): Observable<MediaModel> {
    var endpoint = `/${id}`;
    return this.http.get<MediaModel>(`${this._uri}${endpoint}`);
  }

  /**
   * Removes a media
   * @returns {Observable<MediaModel>}
   */
  delete(id: string): Observable<boolean> {
    var endpoint = `/${id}`;
    return this.http.delete<boolean>(`${this._uri}${endpoint}`);
  }


  /**
   * Pulls a list of modules
   * @returns {Observable<any>}
   */
  getImageData(id: string, width?: number, height?: number): Observable<any> {
    let endpoint = `/image/${id}`;
    if (width) {
      endpoint += `/w/${width}`
    }
    if (height) {
      endpoint += `/h/${height}`
    }
    return this.http.get(`${this._uri}${endpoint}`);
    // return this.http.get<Blob>(`${this._uri}${endpoint}`, { responseType: 'blob'});
  }

  getMultiple(ids: string[]): Observable<MediaModel[]> {
    const endpoint = `/GetMultiple`;
    return this.http.post<MediaModel[]>(`${this._uri}${endpoint}`, ids);
  }

  getMedia(id: string): Observable<MediaBlobModel> {
    const endpoint = `/file/${id}`;
    return this.http.get<MediaBlobModel>(`${this._uri}${endpoint}`);
  }

  /**
 * Updates the media.
 * @returns {Observable<BaseResultModel<MediaModel>>}
 */
  update(model: MediaModel): Observable<BaseResultModel<MediaModel>> {
    const endpoint = '';
    if (model.mediaId != null && model.mediaId !== '') {
      return this.http.put<BaseResultModel<MediaModel>>(`${this._uri}${endpoint}/${model.mediaId}`, model);
    } else {
      return this.http.post<BaseResultModel<MediaModel>>(`${this._uri}${endpoint}`, model);
    }
  }

}
