import { Pipe, PipeTransform } from '@angular/core'
import { LocationsService } from 'src/app/services/locations/locations.service';
import { mergeMap, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


@Pipe({ name: 'locationName' })
export class LocationNamePipe implements PipeTransform {
  constructor(private locationService: LocationsService) {

  }
  transform(value: string, args: string): any {
    return this.locationService.list()
      .pipe(flatMap(locs => {
       // console.log(locs);
        var filter = locs.filter(f => f.locationNodeString == value);
        return filter.length > 0 ? of(filter[0].formattedLocationName) : of("")
      }));
  }
}
