import { OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { BaseUiModel } from './base-ui.model';

export abstract class BaseComponent<T extends BaseUiModel = BaseUiModel> implements OnDestroy {
  protected collector: Subscription = new Subscription();
  protected destroyed$: Subject<void> = new Subject();
  readonly uiModel: T;

  constructor() {
    this.uiModel = this.initModel();
  }

  protected initModel(): T {
    // @ts-ignore
    return new BaseUiModel();
  }

  unsubscribeOnDestroy(child: Subscription) {
    this.collector.add(child);
  }

  ngOnDestroy() {
    this.collector.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
