import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, ViewChild, AfterViewInit, ViewContainerRef } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { SwalPartialTargets, SwalComponent } from '@sweetalert2/ngx-sweetalert2'; 


@Component({
  selector: 'synapze-cx-modal',
  templateUrl: './synapze-cx-modal.component.html'
})
export class SynapzeCxModalComponent {

  modalRef: BsModalRef;
  
  @ViewChild("template") template: TemplateRef<any>;
  @Input() modalClass: string = "modal-lg modal-info";
  @Input() fancy: boolean = false;
  @Input() showCloseIcon: boolean = true;
  @ViewChild('swalComponent') private swalComponent: SwalComponent;

  constructor(private modalService: BsModalService,
    public readonly swalTargets: SwalPartialTargets) {
  }

  ngAfterViewInit() {
  }

  public show(): void {
    
    if (!this.fancy) {
      this.modalRef = this.modalService.show(this.template, { class: `${this.modalClass}`, backdrop: 'static' });
    }
    else {
      this.swalComponent.show();
    }
  }

  public showWarning(): void {
    var modalClass = `${this.modalClass} modal-warning`;
    this.modalRef = this.modalService.show(this.template, { class: `${modalClass}`, backdrop: 'static' });
    //this.swalComponent.show();
  }

  public hide(): void {
    if (!this.fancy) {
      if (this.modalRef !=null)
        this.modalRef.hide();
    }
    else {
      this.swalComponent.nativeSwal.close();
    }
  }
}
