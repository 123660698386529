export enum State { Initial, Ready, Pending }

export class BaseUiModel {
  state = State.Initial;
  public message: string;

  constructor(state: State = State.Initial ) {
    this.state = state;
  }

  get isReady(): boolean {
    return this.state === State.Ready;
  }

  get isPending(): boolean {
    return this.state === State.Pending;
  }

  ready = () => this.state = State.Ready;
  pending = () => this.state = State.Pending;
}
