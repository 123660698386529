import { Directive, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[synapze-cx-grid-row]',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None
})
export class SynapzeCXGridRowComponent { }


