import { Component, OnInit, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, ViewChild } from '@angular/core';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { LocationsService } from 'src/app/services/locations/locations.service';
import { RolesService } from 'src/app/services/roles/roles.service';
import { SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { dataGridModel } from 'src/app/models/dataGridModel';

@Component({
  selector: 'jobRole-tenantRole-selector',
  templateUrl: './jobRole-tenantRole-selector.component.html'
})
export class JobRoleTenantRoleSelectorComponent implements OnInit {

  editRoleSelectorForm: FormGroup;

  private _jobRoleCodes: string[] = [];
  private _systemRoleCodes: string[] = [];
  private _customRoleCodes: string[] = [];
  private _groupCodes: string[] = [];
  private _placeholder :string;

  _onlyShowTotalNoOfItemsSelected = false;

  @Input()
  public set onlyShowTotalNoOfItemsSelected(val: boolean) { this._onlyShowTotalNoOfItemsSelected = val }
  public get onlyShowTotalNoOfItemsSelected(): boolean { return this._onlyShowTotalNoOfItemsSelected; }


  @Input() showselection:boolean=true;
  @Input() source:string;


  private _readOnly: boolean = false;
  @Input()
  set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
  }
  get readOnly(): boolean { return this._readOnly; }



  @Input() set placeholder(value:string){
    this._placeholder = value;
  }
  get placeholder():string{
    return this._placeholder;
  }

  @Input() set jobRoleCodes(value: string[]) {
    this._jobRoleCodes = value;
    this.fillJobDesignationControl();

  }
  get jobRoleCodes(): string[] {
    return this._jobRoleCodes;
  }

  @Input() set groupCodes(value: string[]) {
    this._groupCodes = value;
    this.fillJobDesignationControl();
  }
  get groupCodes(): string[] {
    return this._groupCodes;
  }

  @Input() set systemRoleCodes(value: string[]) {

    this._systemRoleCodes = value;
    this.fillJobDesignationControl();

  }
  get systemRoleCodes(): string[] {
    return this._systemRoleCodes;
  }

  @Input() set customRoleCodes(value: string[]) {

    this._customRoleCodes = value;
    this.fillJobDesignationControl();

  }
  get customRoleCodes(): string[] {
    return this._customRoleCodes;
  }

  @Output() jobRoleCodesChange = new EventEmitter();
  @Output() groupCodesChange = new EventEmitter();
  @Output() systemRoleCodesChange = new EventEmitter();
  @Output() customRoleCodesChange = new EventEmitter();

  jobDesignations = [];

  constructor(private tenantService: TenantService,
    private formBuilder: FormBuilder,
    private rolesService: RolesService) {
  }

  ngOnInit() {
    this.getJobDesignations();

    this.editRoleSelectorForm = this.formBuilder.group({
      JobDesignations: null
    });

    this.fillJobDesignationControl();

    //this.editRoleSelectorForm.controls['JobDesignations'].valueChanges.subscribe(
    //  value => {
    //    console.log(this.lookforJobDesignationChanges);
    //    if (this.lookforJobDesignationChanges)
    //    {
    //      this.fillJobDesignationFields();
    //      this.fireChangeEvent();
    //    }
    //  });
  }

  onChange($event) {
    this.fillJobDesignationFields();
    this.fireChangeEvent();
  }


  public fillJobDesignationControl(): void {
    var designations = [];
    if (this.jobRoleCodes != null && this.jobRoleCodes.length > 0)
      this.jobRoleCodes.forEach(res => designations = [...designations, 'JOB_' + res]);

    if (this.systemRoleCodes != null && this.systemRoleCodes.length > 0)
      this.systemRoleCodes.forEach(res => designations = [...designations, 'SYSTEM_' + res]);
    if (this.customRoleCodes != null && this.customRoleCodes.length > 0)
      this.customRoleCodes.forEach(res => designations = [...designations, 'CUSTOM_' + res]);
    

    if (this.editRoleSelectorForm !=null)
      this.editRoleSelectorForm.controls['JobDesignations'].setValue(designations);
  }


  fillJobDesignationFields(): void {
    this._jobRoleCodes = [];
    this._systemRoleCodes = [];
    this._customRoleCodes = [];
    if (this.editRoleSelectorForm.controls['JobDesignations'].value != null) {
      (<string[]>this.editRoleSelectorForm.controls['JobDesignations'].value).forEach(item => {
        if (item != null && item != '') {

          if (item.startsWith("JOB_"))
            this._jobRoleCodes.push(item.replace("JOB_", ""));
          else if (item.startsWith("CUSTOM_"))
            this._customRoleCodes.push(item.replace("CUSTOM_", ""));
          else if (item.startsWith("SYSTEM_"))
            this._systemRoleCodes.push(item.replace("SYSTEM_", ""));
        }
      });
    }
  }


  private getJobDesignations(): void {

    this.jobDesignations = [];

    //for Roles

    //let searchParams: dataGridModel<{}> = new dataGridModel<{}>();

    //this.rolesService.list(searchParams).subscribe(job => {
    //  console.log(job);
    //  if (job != null) {
    //    job.forEach(jb => {

    //      if (jb.roleType == 'Custom')
    //        this.jobDesignations = [...this.jobDesignations, { "name": jb.name, "id": 'CUSTOM_' + jb.id, "category": "Custom role" }];
    //      else if (jb.roleType == 'System')
    //        this.jobDesignations = [...this.jobDesignations, { "name": jb.name, "id": 'SYSTEM_' + jb.id, "category": "System role" }];

    //    });
    //  }

      
    //});

    this.tenantService.getTenantJobDesignations().subscribe(job => {
      if (job != null) {
        job.forEach(jb => {
          //this.jobDesignations.push({ label: jb.jobDesignationName, value: jb.jobDesignationCode });
          this.jobDesignations = [...this.jobDesignations, { "name": jb.jobDesignationName, "id": 'JOB_' + jb.jobDesignationCode, "category": "Job role" }];
        });
      }
    });
  }

  deleteRole(index: number, roleType: string): void {
    if (!this.readOnly) {
      if (roleType == "job") {
        this.jobRoleCodes.splice(index, 1);
      }
      else if (roleType == "custom") {
        this.customRoleCodes.splice(index, 1);
      }
      else if (roleType == "system") {
        this.systemRoleCodes.splice(index, 1);
      }


      
      this.fillJobDesignationControl();
      this.fireChangeEvent();
    }
  }
  public fireChangeEvent(): void {
    this.jobRoleCodesChange.emit(this._jobRoleCodes);
    this.systemRoleCodesChange.emit(this._systemRoleCodes);
    this.customRoleCodesChange.emit(this._customRoleCodes);
  }
}
