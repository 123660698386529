import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation, EventEmitter, Output, forwardRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'synapze-cx-calendar',
  templateUrl: './synapze-cx-calendar.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SynapzeCxCalendarComponent),
    multi: true,
  }]
})
export class SynapzeCxCalendarComponent implements ControlValueAccessor {

  
  @Input() height: string = "200px";
  model: any;
  locale: any;

  _readOnly: boolean = false;
  @Input()
  public set readOnly(value: boolean) {
    this._readOnly = value;

    if (this.calendarForm != null) {
      if (this._readOnly)
        this.calendarForm.disable();
      else
        this.calendarForm.enable();
    }
  }
  public get readOnly(): boolean {
    return this._readOnly;
  }


  _minDate: any;
  @Input()
  public set minDate(value: any) {
    this._minDate = value;
  }
  public get minDate(): any {
    return this._minDate;
  }

  _selection: string = 'single';
  @Input()
  public set selectionMode(value: string) {
    this._selection = value;
  }
  public get selectionMode(): string {
    return this._selection;
  }

  _dateFormat: string;
  @Input()
  public set dateFormat(value: string) {
    this._dateFormat = value;
  }
  public get dateFormat(): string {
    return this._dateFormat;
  }

  _showButtonBar: boolean;
  @Input()
  public set showButtonBar(value: boolean) {
    this._showButtonBar = value;
  }
  public get showButtonBar(): boolean {
    return this._showButtonBar;
  }

  _placeholder: string;
  @Input()
  public set placeholder(value: string) {
    this._placeholder = value;
  }
  public get placeholder(): string {
    return this._placeholder;
  }


  onChange;
  calendarForm: FormGroup;
  ngOnInit(): void {
    this.calendarForm = new FormGroup({
      selectedDate: new FormControl()
    });

    if (this._readOnly)
      this.calendarForm.disable();
    else
      this.calendarForm.enable();

  }

  writeValue(obj: any): void {
    //this.inputText = obj;
    this.calendarForm.controls["selectedDate"].setValue(obj);
    this.model = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    //this.onChange = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readOnly = isDisabled;
  }

  private _jsonURL = 'assets/calendar-locales/{lang}.json';

  public setCalendarLocale(lang: string){
    this.http.get(this._jsonURL.replace('{lang}',lang)).subscribe(data => {
      this.locale = data;
    });;
  }

  constructor(private http: HttpClient, private userService: UsersService) {
    this.setCalendarLocale(userService.getUserBrowsingLanguage().substring(0, 2));
    this.userService.events().subscribe(evt => {

      if (evt.event == UsersService.LANGUAGE_CHANGE_EVENT) {
        this.setCalendarLocale(userService.getUserBrowsingLanguage().substring(0,2))
      }
    });

    
  }
  
  ngAfterViewInit() {
  }

  

  textChange(changeEvent): void {
    if (this.selectionMode == 'range') {
      if (this.model == null || (this.model.length == 1 && moment(this.model[0]).isAfter(moment(changeEvent))) || this.model.length == 2) {
        this.model = [changeEvent];
      }
      else {
        this.model.push(changeEvent);
      }
    }
    else {
      this.model = changeEvent;
    }
    
    if (this.onChange!=null)
      this.onChange(this.model);
    //console.log(this.model);

  }

  onEditorInit(event: any) {
    //event.editor.root.blur();
    //window.scroll(0, 0);
  }


}
