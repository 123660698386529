import { Injectable, Optional, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import {
    UserModel,
    UserSearchParams,
    UserStatusInfoRequest,
    UserCountByTagResponse,
    UserCountByTagParams,
} from "src/app/models/userModel";
import { AppConfigService } from "../app-config/app-config.service";
import { AppConfig } from "src/app/vos/app-config/app-config";
import { dataGridModel } from "src/app/models/dataGridModel";
import { of, BehaviorSubject } from "rxjs";
import { flatMap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/shared/shared.constants";
import { StorageService } from "ngx-webstorage-service";
import { CORE_SESSION_STORAGE } from "../storage/storage.service";

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class UsersServiceConfig {
    uri = "http://localhost:5000/api/";
}

@Injectable()
/**
 * Service class.
 */
export class UsersService {
    /**
     * Path uri.
     * @type {string}
     * @private
     */
    private _uri = "";
    public user: UserModel = null;
    private subject = new Subject<any>();
    private imageUploaded = new BehaviorSubject("");
    currentMessage = this.imageUploaded.asObservable();

    static readonly CURRENT_USER_FETCHED_EVENT = "currentUserFetched";
    static readonly LANGUAGE_CHANGE_EVENT = "languageChangeEvent";

    /**
     * Url to endpoint api.
     * @type {string}
     */
    private endpoint = "/api/user";
    private config: AppConfig;
    /**
     * Endpoint request headers.
     * @type {HttpHeaders}
     */
    private headers = new HttpHeaders({ "Content-Type": "application/json" });

    /**
     * Component constructor and DI injection point.
     * @param {HttpClient} http
     * @param {AppConfigService} config
     */
    constructor(
        private http: HttpClient,
        configService: AppConfigService,
        private translate: TranslateService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService
    ) {
        if (configService) {
            this.config = configService.get();
            if (this.config != null)
                this._uri = this.config.tenantConfig.authService.serviceUrl;
        }
    }

    /**
     * Pulls a list of User objects.
     * @returns {Observable<UserModel[]>}
     */
    list(
        searchParameters: dataGridModel<UserSearchParams>
    ): Observable<UserModel[]> {
        var getall = "/getallapproved";
        searchParameters.searchParameters.TenantCode = this.config.tenantConfig.tenantCode;
        return this.http.post<UserModel[]>(
            `${this._uri}${this.endpoint}${getall}`,
            searchParameters
        );
    }

    /**
     * Pulls a list of User objects.
     * @returns {Observable<UserModel[]>}
     */
    listByIds(userIds: number[]): Observable<UserModel[]> {
        var getbyids = `/getbyids?userIds=${userIds[0]}`;
        for (var i = 1; i < userIds.length; i++) {
            getbyids += `&userIds=${userIds[i]}`;
        }

        return this.http.get<UserModel[]>(
            `${this._uri}${this.endpoint}${getbyids}`
        );
    }

    /**
     * Pulls a list of User objects.
     * @returns {Observable<UserModel[]>}
     */
    listByUserCodes(userCodes: string[]): Observable<UserModel[]> {
        return this.http.post<UserModel[]>(
            `${this._uri}${this.endpoint}/getbyusercodes`,
            userCodes
        );
    }

    /**
     * Pulls a single User object.
     * @param {number | string} id to retrieve.
     * @returns {Observable<UserModel>}
     */
    show(userCode: string | string): Observable<UserModel> {
        const url = `${this._uri}${this.endpoint}/${userCode}`;
        return this.http.get<UserModel>(url);
    }

    // /**
    //  * Creates a single User object.
    //  * @param {} value to create.
    //  * @returns {Observable<UserModel>}
    //  */
    // create(value: UserModel): Observable<UserModel> {
    //     return this.http
    //         .post<UserModel>(`${this._uri}${this.endpoint}`, JSON.stringify(value), {headers: this.headers});
    // }

    /**
     * Updates a single User object.
     * @param {} value to update.
     * @returns {Observable<User>}
     */
    update(value: UserModel): Observable<any> {
        const url = `${this._uri}${this.endpoint}/${value.userCode}`;
        return this.http.put<void>(url, JSON.stringify(value), {
            headers: this.headers,
        });
    }

    /**
     * Destroys a single User object.
     * @param {number | string} id to destroy.
     * @returns {Observable<void>}
     */
    destroy(userCode: string | string): Observable<void> {
        const url = `${this._uri}${this.endpoint}/${userCode}`;
        return this.http.delete<void>(url, { headers: this.headers });
    }

    resetpassword(changeReq: UserStatusInfoRequest): Observable<any> {
        changeReq.UserInfoChangeType = "PASSWORD_RESET";
        var api = "/resetpassword";
        return this.http.post<any>(
            `${this._uri}${this.endpoint}${api}`,
            changeReq
        );
    }

    changeUserStatus(changeReq: UserStatusInfoRequest): Observable<any> {
        var api = "";
        switch (changeReq.UserInfoChangeType) {
            case "ACTIVATION":
                api = "/activate";
                break;
            case "DEACTIVATION":
                api = "/deactivate";
                break;
            case "PASSWORD_RESET":
                api = "/resetpassword";
                break;
        }

        return this.http.post<any>(
            `${this._uri}${this.endpoint}${api}`,
            changeReq
        );
    }

    activate(changeReq: UserStatusInfoRequest): Observable<any> {
        changeReq.UserInfoChangeType = "ACTIVATION";
        var api = "/activate";

        return this.http.post<any>(
            `${this._uri}${this.endpoint}${api}`,
            changeReq
        );
    }
    deactivate(changeReq: UserStatusInfoRequest): Observable<any> {
        changeReq.UserInfoChangeType = "DEACTIVATION";
        var api = "/deactivate";
        return this.http.post<any>(
            `${this._uri}${this.endpoint}${api}`,
            changeReq
        );
    }

    approve(users: string[], comment: string): Observable<any> {
        const url = `${this._uri}${this.endpoint}/approve`;
        var data = { Users: users, Comment: comment };
        return this.http.post<any>(url, data);
    }

    deny(users: string[], comment: string): Observable<any> {
        const url = `${this._uri}${this.endpoint}/deny`;
        var data = { Users: users, Comment: comment };
        return this.http.post<any>(url, data);
    }

    logOutUser(userId: number) {}

    currentUser(): UserModel {
        var user: UserModel = null;
        if (this.user == null) {
            this.getUserInfo().subscribe((usr) => {
                this.user = usr;
                this.subject.next({
                    event: UsersService.CURRENT_USER_FETCHED_EVENT,
                });
                this.setUserBrowsingLanguage(
                    this.user.browsingLanguagePreference
                );
            });
        } else user = this.user;
        return user;
    }

    events(): Observable<any> {
        return this.subject.asObservable();
    }

    /**
     * Pulls a single User object.
     * @returns {Observable<UserModel>}
     */
    getUserInfo(): Observable<UserModel> {
        if (this.user != null) {
            return of(this.user);
        } else {
            const url = `${this._uri}/api/UserProfile/GetCurrentUser`;
            return this.http.get<UserModel>(url).pipe(
                flatMap((usr) => {
                    if (usr != null) this.user = usr;
                    return of(usr);
                })
            );
        }
    }

    getUserBrowsingLanguage() {
        const currentLang = this.sessionStorage.get(
            Constants.USER_BROWSER_LANGUAGE
        );
        if (
            currentLang !== undefined &&
            currentLang != null &&
            currentLang != ""
        ) {
            return currentLang;
        } else {
            var user = this.currentUser();
            if (
                user != null &&
                user.browsingLanguagePreference != null &&
                user.browsingLanguagePreference != ""
            )
                return user.browsingLanguagePreference;
            else return this.translate.getBrowserCultureLang();
        }
    }

    updateUserBrowsingLanguage(locale: string): Observable<any> {
        this.setUserBrowsingLanguage(locale);

        var user = this.currentUser();
        if (user != null) {
            const urlUpdate = `${this._uri}/api/UserProfile/userBrowserLanguage`;
            return this.http.post<boolean>(urlUpdate, {
                id: user.userCode,
                langauge: locale,
            });
        }
    }

    setUserBrowsingLanguage(locale: string) {
        if (locale != null && locale != "") {
            this.sessionStorage.set(Constants.USER_BROWSER_LANGUAGE, locale);
            this.useBrowsingLanguage();
        }
    }

    useBrowsingLanguage(): void {
        //var language = this.getUserBrowsingLanguage();
        //this.translate.use(language);
        this.subject.next({ event: UsersService.LANGUAGE_CHANGE_EVENT });
    }

    getUserCountByTag(
        searchParameters: UserCountByTagParams
    ): Observable<UserCountByTagResponse> {
        var getall = "/countbytag";
        return this.http.post<UserCountByTagResponse>(
            `${this._uri}${this.endpoint}${getall}`,
            searchParameters
        );
    }
}
