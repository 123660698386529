import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'validation-summary',
  templateUrl: './validation-summary.component.html'
})
export class ValidationSummaryComponent implements OnInit, OnChanges {

  @Input() errors = [];
  filteredErrors = [];
  @Input() useTranslation: boolean = false;
  @Input() showOnlyNonAssociated: boolean = false;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.errors != null && this.showOnlyNonAssociated) {
      this.filteredErrors = this.errors.filter(x => x.errorFields == null || x.errorFields.length == 0);
    }
    else {
      this.filteredErrors = this.errors;
    }

    if (this.useTranslation && this.filteredErrors != null) {
      this.filteredErrors.forEach(error => {
        if (error.errorParameters == null)
          error.errorParameters = {};

        error.errorParameters.defaultText = this.removeSingleQuotes(error.errorDescription);
      });
    }
  }

  removeSingleQuotes(data: string): string {
    return data.replace("'", "");
  }
}
