import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { Permission } from 'src/app/vos/permission/permission';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { Constants } from '../../shared/shared.constants';
import { AppConfig } from 'src/app/vos/app-config/app-config';
import { OAuthService } from 'angular-oauth2-oidc';
import { SynapzeCxOAuthserviceService } from 'src/app/auth/synapze-cx-oauthservice.service';
import { CORE_SESSION_STORAGE } from '../storage/storage.service';
import { StorageService } from 'ngx-webstorage-service';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class PermissionsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class PermissionsService {

  /**
   * Path uri.
   * @type {string}
   * @private
   */
  private _uri = 'https://c08dc89a-c8de-414d-bdf9-b85fb2958be0.mock.pstmn.io/api/';
  //private _uri = 'http://localhost:5000/api/UserProfile/UserPermissions';
  

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   * @param {PermissionsServiceConfig} config
   */
  constructor(private http: HttpClient,
    private appConfigService: AppConfigService,
    @Optional() config: PermissionsServiceConfig,
    @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService) {
    if (config) {
      this._uri = config.uri;
    }
    
    this._appConfigService = appConfigService;
  }

  /**
   * Pulls a list of Permission objects.
   * @returns {Observable<Permission[]>}
   */
  list(endpoint: string): Observable<Permission[]> {
    //if (this._appConfigService) {
    //  this._uri = this._appConfigService.get().apiEndpoints.permissionsService;
   // }
    //return this.http.get<Permission[]>(`${this._uri}${this.endpoint}`);
    var url = `${endpoint}/api/UserProfile/UserPermissions`;
    return this.http.get<Permission[]>(`${url}`);
  }

   getUserPermissions():Promise<any>{
    var promise;
    var url = `${this.appConfigService.get().tenantConfig.permissionService.serviceUrl}/api/UserProfile/UserPermissions`;  
    promise = this.http.get<Permission[]>(url).toPromise()
    .then(x=> {
      var resp = x as Permission[];
      resp = resp.filter(x=> (x != null)&& (x != ''))
      this.sessionStorage.set(Constants.PERMISSIONS_STORAGE_KEY,JSON.stringify(resp));  
      return resp;
    });
    return promise;
  }

  checkUserPermission(permission: string) {
    var permissions: string[] =[];
    if (this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)) {
      permissions = permissions.concat(JSON.parse(this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)));
    }

    return permissions.includes(permission);
  }

  userPermissions(): string[] {
    var permissions: string[] = [];
    if (this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)) {
      if(this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)!=null)
        permissions = permissions.concat(JSON.parse(this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)));
    }

    //return ["ADMIN_APPROVE_USER"];


    return permissions;
  }


  checkUserPermissions(permissions: string[], allPermissions: boolean): boolean {

    

    var userPermissions: string[] = [];
    if (this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)) {
      userPermissions = userPermissions.concat(JSON.parse(this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY)));

    }

    //userPermissions = ["ADMIN_APPROVE_USER"];

    var accessGranted = false;
    var permExists = false;
    if (permissions.length <= 0)
      accessGranted = true;
    else {
      if (allPermissions) {
        accessGranted = permissions.filter(perm => !userPermissions.includes(perm)).length == 0;
      }
      else {
        accessGranted = permissions.filter(perm => userPermissions.includes(perm)).length > 0;
      }
    }

    //console.log(allPermissions);
    //console.log(allPermissions == true);
    //console.log(permissions.filter(perm => userPermissions.includes(perm)).length);
    //console.log(permissions);
    //console.log(userPermissions);
    //console.log(accessGranted);

    return accessGranted;
  }

  cleanAllCachedPermissions()
  {
    if (this.sessionStorage.get(Constants.PERMISSIONS_STORAGE_KEY))
    {
      sessionStorage.removeItem(Constants.PERMISSIONS_STORAGE_KEY);
    }
  }


}
