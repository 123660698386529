<ng-container *ngIf="tabluarFormat">
  <div 
    class="search-results scroll-host"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="scrollWindow"
    (scrolled)="onGridScroll()"
  >
    <table class="table">
      <thead>
        <th *ngFor="let item of columns">
          <template [ngTemplateOutlet]="columnDefs" [ngTemplateOutletContext]="{item: item}"> </template>
        </th>
      </thead>

      <tbody class="list">
        <tr *ngFor="let item of items">
            <template [ngTemplateOutlet]="rowDefs" [ngTemplateOutletContext]="{item: item}"> </template>
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="items != null && items.length == 0; then thenTemplate"></ng-container>
    <ng-template #thenTemplate>
      <div class="alert alert-warning">
        <span>{{emptyListMessage!=undefined ? emptyListMessage : 'No matching records found'}}</span>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="!tabluarFormat">
  <div
    class="search-results question-grid"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onGridScroll()"
  >
    <div *ngFor="let item of items">
      <template [ngTemplateOutlet]="rowDefs" [ngTemplateOutletContext]="{ item: item }"> </template>
    </div>

    <ng-container *ngIf="items != null && items.length == 0; then thenTemplate"></ng-container>
    <ng-template #thenTemplate>
      <div class="alert alert-warning">
        <span>No matching records found</span>
      </div>
    </ng-template>
  </div>
</ng-container>