<div class="footer-section">
  <div class="container">
    <div class="row footer-top">
      <div class="col-xs-6 col-sm-4"><div class="footer-logo" *ngIf="tenantFooterConfiguration?.showLogo == true">
        <img src="assets/css/assets/logo-pevaar.svg"></div></div>
      <div class="col-xs-12 col-sm-4 footer-social">
        <a *ngFor="let tenantInfo of tenantFooterConfiguration?.tenantFooterSocialMediaConfigurations" href="//{{tenantInfo.mediaUrl}}" class="footer-social-icon {{tenantInfo.mediaName}}"></a>
      </div>
      <div class="col-xs-6 col-sm-4">
        <div class="footer-select">
          <!--<select name="location" id="location" [(ngModel)]="currentLanguage" (change)="change()">
    <option [value]="local.locale" *ngFor="let local of tenantLocales">
      {{local.localeName}}
    </option>
  </select>-->

          <synapze-cx-simple-dropdown *ngIf="tenantFooterConfiguration?.showLanguageSwitchingOption == true"
                                      class="footer-lang"
                                      [enableMultiSelection]="false"
                                      [readOnly]="false"
                                      (onSelectionChanged)="change($event)"
                                      [(dropdownitems)]="languages"
                                      [(ngModel)]="currentLanguage">
          </synapze-cx-simple-dropdown>

          <!--<select name="location" id="location" [(ngModel)]="currentLanguage" (change)="change()">
    <option value="en-US">
      English
    </option>
    <option value="fr-CA">
      French
    </option>
  </select>-->
        </div>
      </div>
    </div>
    <!--<div class="footer-line"></div>
  <div class="row footer-menu-1">
    <div class="col-xs-12">
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur dolor sit amet maecenas</a></li>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">Lorem ipsum</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur</a></li>
          <li><a href="#">adipiscing elit</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <div class="footer-col-header"><h3>Heading</h3></div>
        <ul>
          <li><a href="#">Lorem ipsum</a></li>
          <li><a href="#">dolor sit amet</a></li>
          <li><a href="#">consectetur</a></li>
        </ul>
      </div>
    </div>
  </div>-->

    <div class="row">
      <div class="col-xs-12"><div class="footer-line"></div></div>
      <div class="col-xs-6 mobile-100">
        <div class="footer-menu-2">
          <ul>
            <li *ngIf="tenantFooterConfiguration?.termsAndConditions == true"><a href="#">{{'core-common-policies_termsconditionslabel' | translate : '{ defaultText :  "Terms and Conditions" }'}}</a></li>
            <li *ngIf="tenantFooterConfiguration?.privacy == true"><a href="#">{{'core-common-policies_privacypolicylabel' | translate : '{ defaultText :  "Privacy Policy" }'}}</a></li>
            <li *ngFor="let tenantInfo of tenantFooterConfiguration?.additionalLinks"><a href="//{{tenantInfo.linkUrl}}">{{tenantInfo.linkName}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xs-6 mobile-100">
        <div class="footer-menu-3">
          <a *ngIf="tenantFooterConfiguration?.submitAnIssue == true" href="{{'core-common-submit_an_issue_email' | translate}}" class="footer-contact-icon submit">{{'core-common-email' | translate : '{ defaultText :  "p2p@bondbrandloyalty.com" }'}}</a>
          <a *ngIf="tenantFooterConfiguration?.contactNumber" class="footer-contact-icon tel">{{tenantFooterConfiguration.contactNumber}}</a>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="footer-bottom">
  <div class="container">
    <div class="row">
      <div class="col-xs-12" *ngIf="tenantFooterConfiguration?.poweredBySynapzeCX == true">{{'core-common-poweredby' | translate : '{ defaultText :  "Powered by" }'}}<img alt="synapze cx" src="assets/css/assets/synapze-cx-logo.png"></div>
    </div>
  </div>
</div>
