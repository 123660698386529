import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseResultModel } from 'src/app/models/baseResultModel';

@Component({
  selector: 'synapze-cx-form-control',
  templateUrl: './synapze-cx-form-control.component.html',
})
export class SynapzeCxFormControlComponent implements OnInit, OnChanges {

  @Input() errors : BaseResultModel<any>;
  @Input() fields = [];
  @Input() useTranslation: boolean = true;
  filteredErrors: any;
  
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.errors != null && this.errors.validationErrors !=null && this.fields != null && this.fields.length > 0) {
      this.filteredErrors = this.errors.validationErrors.filter(x => x.errorFields.filter(y => this.fields.filter(z => z == y).length > 0).length > 0);
      this.filteredErrors.forEach(error =>
      {
        if (error.errorParameters == null)
          error.errorParameters = {};

        error.errorParameters.defaultText = this.removeSingleQuotes(error.errorDescription);
      });
    }
  }

  removeSingleQuotes(data: string): string {
    return data.replace("'", "");
  }

}
