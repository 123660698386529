import { Injectable, Optional, Inject } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
    OAuthService,
    LoginOptions,
    ReceivedTokens,
    OAuthErrorEvent,
} from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";

import { AppConfigService } from "../services/app-config/app-config.service";
import { PermissionsService } from "../services/permissions/permissions.service";
import { Constants } from "../shared/shared.constants";
import { AppConfig } from "../vos/app-config/app-config";
import * as moment from "moment";
import { OAuthErrorEventReason } from "./models/synapze-auth-event-models";
import {
    CORE_SESSION_STORAGE,
    CORE_LOCAL_STORAGE,
} from "../services/storage/storage.service";
import { StorageService } from "ngx-webstorage-service";

@Injectable({
    providedIn: "root",
})
export class SynapzeCxOAuthserviceService {
    isLoaded: boolean;
    loginInProgress: boolean = false;
    impersonate: boolean = false;

    constructor(
        private oauthService: OAuthService,
        private appConfigService: AppConfigService,
        private permissionsService: PermissionsService,
        private router: Router,
        @Inject(CORE_LOCAL_STORAGE) private localStorage: StorageService,
        @Inject(CORE_SESSION_STORAGE) private sessionStorage: StorageService
    ) {
        this.configureApplication(appConfigService.get());
    }

    getOrigin(): string {
        if (!window.location.origin) {
            return (
                window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port ? ":" + window.location.port : "")
            );
        } else {
            return window.location.origin;
        }
    }

    private configureApplication(config: AppConfig) {
        this.localStorage.remove(Constants.LOGOUT_IN_PROGRESS);

        this.oauthService.configure({
            issuer: config.tenantConfig.authService.serviceUrl,
            clientId: config.tenantConfig.authService.clientId,
            redirectUri: this.getOrigin() + "/index.html",
            silentRefreshRedirectUri: this.getOrigin() + "/silent-refresh.html",
            postLogoutRedirectUri: this.getOrigin() + "/logout",
            scope: config.tenantConfig.authService.scopes,
            oidc: true, // do not remove this. Events will not be triggered if removed.
            requireHttps: false,
            customQueryParams: {
                acr_values: `${this.GetAcrValues(config)}`,
                uilocales: config.tenantConfig.defaultLocale,
            },
        });
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.loadDiscoveryDocumentAndTryLogin();

        this.oauthService.events.subscribe((e) => {
            //console.log("EVENT RECEIVED: " + e.type);
            // console.log("EVENT: " + e);
            if (e.type == "token_received") {
                this.permissionsService
                    .list(config.tenantConfig.permissionService.serviceUrl)
                    .subscribe((permissions) => {
                        if (permissions != null) {
                            permissions = permissions.filter(
                                (x) => x != null && x != ""
                            );
                            this.sessionStorage.set(
                                Constants.PERMISSIONS_STORAGE_KEY,
                                JSON.stringify(permissions)
                            );
                            //https://github.com/manfredsteyer/angular-oauth2-oidc/blob/master/docs-src/preserving-state.md
                            if (
                                this.oauthService.state != null &&
                                this.oauthService.state != "" &&
                                this.oauthService.state !=
                                    document.location.href
                            )
                                document.location.href = decodeURI(
                                    this.oauthService.state
                                );
                        }
                    });
            }
            if (e.type == "logout") {
                //console.log("logout" + JSON.stringify(e));
            }
            if (e.type == "token_expires") {
                //console.log("TOKEN EXPIRED" + JSON.stringify(e));
            }
            if (e.type == "silent_refresh_error") {
                // console.log("silent_refresh_error" + JSON.stringify(e));
                var errorEvent = e as OAuthErrorEvent;

                if (errorEvent.reason != null) {
                    var reason = errorEvent.reason as OAuthErrorEventReason;
                    if (reason.error === 'login_required"') {
                        this.LogOut();
                    }
                }
            }
            //if (e.type == 'invalid_nonce_in_state')
            //console.log('!!!invalid_nonce_in_state' + JSON.stringify(e));
        });
    }

    public Login(retrunUrl: any): Promise<any> {
        // console.log("Enter Login");
        this.loginInProgress = true;
        return this.oauthService
            .loadDiscoveryDocumentAndTryLogin()
            .then((doc) => {
                if (
                    !this.oauthService.hasValidIdToken() ||
                    !this.oauthService.hasValidAccessToken() ||
                    this.IsTokenExpired()
                ) {
                    retrunUrl =
                        retrunUrl == null ? document.location.href : retrunUrl;
                    this.oauthService.initImplicitFlow(encodeURI(retrunUrl));
                    this.loginInProgress = false;
                } else {
                    //console.log("FOUND VALID TOKEN. SKIPPING IMPLICIT FLOW.");

                    return this.permissionsService
                        .list(
                            this.appConfigService.get().tenantConfig
                                .permissionService.serviceUrl
                        )
                        .subscribe((permissions) => {
                            this.loginInProgress = false;
                            if (permissions != null) {
                                permissions = permissions.filter(
                                    (x) => x != null && x != ""
                                );
                                this.sessionStorage.set(
                                    Constants.PERMISSIONS_STORAGE_KEY,
                                    JSON.stringify(permissions)
                                );
                            }
                        });
                }
            });
        //.finally(() => console.log("Exiting login"));
    }

    public HasValidToken(): boolean {
        var ret =
            this.oauthService.hasValidAccessToken() &&
            this.oauthService.hasValidIdToken();
        // console.log("ACCEESS TOKEN FOUND:" + ret);
        // console.log("TOKEN EXPIRY:: " + moment(this.oauthService.getAccessTokenExpiration()).toDate());
        // console.log("NOW:: " +  moment().toDate());
        // console.log("EXPIRED:: "+this.IsTokenExpired());
        ret = ret && !this.IsTokenExpired();
        return ret;
    }

    public GetAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    LogOut(): void {
        this.localStorage.set(Constants.LOGOUT_IN_PROGRESS, "true");
        this.oauthService.logOut();
        this.sessionStorage.clear();
    }

    LogOutForImpersonate(
        impersonatorName: string,
        userName: string,
        tenantCode: string
    ): void {
        this.localStorage.set(Constants.LOGOUT_IN_PROGRESS, "true");
        this.oauthService.logOut(
            false,
            impersonatorName + ";" + userName + ";" + tenantCode
        );
        this.sessionStorage.clear();
    }

    IsTokenExpired(): boolean {
        var expiryDate = moment(
            this.oauthService.getAccessTokenExpiration()
        ).toDate();
        var now = moment();
        return now.diff(expiryDate) < 0 ? false : true;
    }

    IsloginInProgress(): boolean {
        return this.loginInProgress;
    }

    IsloginOutProgress(): boolean {
        var logOutStarted = "false";
        logOutStarted = this.localStorage.get(Constants.LOGOUT_IN_PROGRESS);

        return logOutStarted != null && logOutStarted == "true";
    }

    private GetAcrValues(config: AppConfig): string {
        var toReturn = `tenant:${config.tenantConfig.tenantCode}`;

        if (
            config.tenantConfig.authService.useExernalProvider != null &&
            config.tenantConfig.authService.useExernalProvider == true
        ) {
            toReturn =
                toReturn +
                ` idp:${config.tenantConfig.authService.externalProviderConfigurationName}`;
        }
        //console.log(toReturn);
        return toReturn;
    }
}
