import { Injectable, Optional } from '@angular/core';
import { GroupModel, GroupSearchModel } from 'src/app/models/groupModel';
import { AppConfigService } from '../app-config/app-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { dataGridModel } from 'src/app/models/dataGridModel';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {


  /**
     * Path uri.
     * @type {string}
     * @private
     */
  private _uri = '/api/group';

  /**
   * Url to endpoint api.
   * @type {string}
   */
  private endpoint = '';

  private _appConfigService: AppConfigService;

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, @Optional() appConfigService: AppConfigService) {
    this._appConfigService = appConfigService;
    if (this._appConfigService) {
      this._uri = `${this._appConfigService.get().tenantConfig.authService.serviceUrl}` + this._uri;
    }
  }

  /**
  * Pulls a list of Roles objects.
  * @returns {Observable<RoleModel[]>}
  */
  getRole(groupId: number): Observable<GroupModel> {
    var endpoint = `/${groupId}`;
    return this.http.get<GroupModel>(`${this._uri}${endpoint}`);
    //return of(GroupsService.groups.find(g => g.groupId == groupId));
  }

  /**
   * Pulls a list of Roles objects.
   * @returns {Observable<RoleModel[]>}
   */
  list<T>(searchParameters: dataGridModel<T>): Observable<GroupModel[]> {
    var endpoint = '/search';
    return this.http.post<GroupModel[]>(`${this._uri}${endpoint}`, searchParameters);
    //return of(GroupsService.groups);
  }


  /**
  * Updates the role.
  * @returns {Observable<any>}
  */
  update(model: GroupModel): Observable<any> {
   // console.log(model);
    var endpoint = '';
    if (model.groupId > 0)
      return this.http.put<any>(`${this._uri}${endpoint}/${model.groupId}`, model);
      //GroupsService.groups[GroupsService.groups.findIndex(g => g.groupId == model.groupId)] = model;
    else
      return this.http.post<any>(`${this._uri}${endpoint}`, model);
      //GroupsService.groups.push(model);

    
    return of(model);
  }

  /**
  * delete the role
  *  * @returns {Observable<any>}
  */
  deleteGroup(groupId: number): Observable<any> {
    var endpoint = `/${groupId}`;
    return this.http.delete<any>(`${this._uri}${endpoint}`);
  }

  validateGroup(model: GroupModel): Observable<any> {
    //console.log(model);
    var endpoint = '/validate';
    return this.http.post<any>(`${this._uri}${endpoint}`, model);

  }
}
