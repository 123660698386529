import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';


import { RolesService } from '../../services/roles/roles.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { ModulePermissions } from '../../models/appModules.permissions';
import { AppConstants } from '../../app.constants';
import { SharedModule } from '../../shared/shared.module';

@Injectable()
export class GroupsGuard implements CanActivate {
  constructor(private permissionsService: PermissionsService, private modulePermissions: ModulePermissions, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    //var requiredPermissions = [
    //  this.modulePermissions.CREATE_ROLE,
    //this.modulePermissions.CREATE_SYSTEM_ROLE,
    //this.modulePermissions.DELETE_ROLE,
    //this.modulePermissions.DELETE_SYSTEM_ROLE,
    //this.modulePermissions.UPDATE_ROLE,
    //this.modulePermissions.UPDATE_SYSTEM_ROLE
    //];

    //if (this.permissionsService.checkUserPermissions(requiredPermissions, false))
    //  return true;
    //else
    //  return false;
    return true;
  }
}
