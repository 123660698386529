import { Injectable } from '@angular/core';
import { ReplaySubject } from "rxjs";
import { TagManager } from './tagManager';


@Injectable()
export class TagManagerService {
  private static instance: TagManager = null;
  private eventTracker$: ReplaySubject<any> = new ReplaySubject();
  private dataStream$: ReplaySubject<any> = new ReplaySubject();
  private currency: string;
  private precision: number = 3;
  private language: string;

  private readyTracker$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public get settings() {
    return {
      language: this.language,
      currency: this.currency,
      precision: this.precision
    }
  }

  public get eventTrack$() {
    return this.eventTracker$.asObservable();
  }

  public get dataLayer$() {
    return this.dataStream$.asObservable();
  }

  public get isReady$() {
    return this.readyTracker$.asObservable();
  }

  public get isInitialized() {
    return !!TagManagerService.instance;
  }

  constructor() {
    this.readyTracker$.next(false);
  }

  public init(containerIds?: string[], auth?: string, preview?: string, dataLayerName?: string, language?: string, currency?: string) {
    if (TagManagerService.instance instanceof TagManager) {
      //console.error('Google tag manager already loaded.');
      return;
    }

    TagManagerService.instance = TagManager.getInstance(containerIds || [], auth, preview, dataLayerName || 'dataLayer', language || 'en', currency || 'EUR');
    this.currency = currency;
    this.language = language;
    if (!TagManagerService.instance) {
      console.info('no gtm container installed');
      return;
    }
    this.dataLayer$.subscribe(e => {
      TagManagerService.instance.dataLayer.push(e);
    });
    this.readyTracker$.next(true);
  }

  public push(data: any) {
    this.dataStream$.next(data);
  }

  public pushCustomEvent(eventName: string, attributes: any) {

    this.push({
      event: eventName,
      attributes: attributes
    });
  }

  public pushEcommerceEvent(eventName: string, eventData: any) {
    this.push(Object.assign({}, {
      event: eventName
    }, eventData))
  }
}
