import {
    Component,
    Input,
    ViewChild,
    OnInit,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewContainerRef,
} from "@angular/core";
import { MenuItem } from "primeng/api";
import { SynapzeCxOAuthserviceService } from "src/app/auth/synapze-cx-oauthservice.service";
import { SynapzeCxModalComponent } from "../synapze-cx-modal/synapze-cx-modal.component";
import { Router } from "@angular/router";
import { UsersService } from "src/app/services/users/users.service";
import { UserModel } from "src/app/models/userModel";
import { MediaService } from "src/app/services/media/media.service";
import { DomSanitizer } from "@angular/platform-browser";
import { TenantModuleResolutionService } from "../../../services/tenant/tenant-navigation-resolution-service";
import { TranslateService } from "@ngx-translate/core";
import { AppConfigService } from "src/app/services/app-config/app-config.service";

@Component({
    selector: "user-profile-menu",
    templateUrl: "./user-profile.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class UserProfileComponent implements OnInit {
    coreSPAURL = "";
    constructor(
        private oauthService: SynapzeCxOAuthserviceService,
        private ref: ChangeDetectorRef,
        private mediaService: MediaService,
        private router: Router,
        private readonly translate: TranslateService,
        private sanitizer: DomSanitizer,
        private userService: UsersService,
        private tenantNavResolutionService: TenantModuleResolutionService,
        private appConfigService: AppConfigService
    ) {}
    @Input() public adminView = false;
    @Input() public mobileView = false;
    switchLabel: string;
    _altUrl = "";
    user: UserModel;
    userName = "";
    userMenuItems: MenuItem[];
    noProfilePict = false;
    teamManager = false;

    @Input() infoVisible = false;

    @ViewChild("logOutConfirmation", { read: ViewContainerRef })
    logOutConfirmation: SynapzeCxModalComponent;

    ngOnInit() {
        this.userService.currentMessage.subscribe((message) => {
            if (message !== "") {
                this.mediaService.getImageData(message).subscribe(
                    (result) => {
                        if (
                            result != null &&
                            result.dataType === "IMAGE" &&
                            result.data != null
                        ) {
                            this.user.pictureUrl = this.sanitizer.bypassSecurityTrustUrl(
                                `data:${result.contentType};base64, ${result.data}`
                            );
                            this.noProfilePict = false;
                        } else {
                            this.noProfilePict = true;
                        }
                        this.ref.detectChanges();
                    },
                    (error) => {
                        this.noProfilePict = true;
                        this.ref.detectChanges();
                    }
                );
            }
        });

        let currentUser = this.userService.currentUser();
        if (currentUser !== null) {
            this.user = currentUser;
            this.userName = this.user.firstName + " " + this.user.lastName;
            this.validatePicture();
        } else {
            this.userService.getUserInfo().subscribe((data) => {
                this.user = data;
                this.validatePicture();
            });
        }

        this.userMenuItems = [];
        if (
            !this.adminView &&
            this.tenantNavResolutionService.hasAnyModuleAdminPermission()
        ) {
            this.translate
                .get(["core-common-profile_menu_switch_to_admin"], {
                    defaultText: "Switch to Admin",
                })
                .subscribe((res: string) => {
                    this.switchLabel =
                        res["core-common-profile_menu_switch_to_admin"];
                    this.userMenuItems.push({
                        label: this.switchLabel,
                        routerLink: "/admin",
                        routerLinkActiveOptions: { exact: true },
                    });
                    this.ref.detectChanges();
                });
        } else if (this.adminView) {
            this.translate
                .get(["core-common-profile_menu_switch to user"], {
                    defaultText: "Switch to User",
                })
                .subscribe((res: string) => {
                    this.switchLabel =
                        res["core-common-profile_menu_switch to user"];
                    this.userMenuItems.push({
                        label: this.switchLabel,
                        routerLink: "/",
                        routerLinkActiveOptions: { exact: true },
                    });
                    this.ref.detectChanges();
                });
        }
        if (this.tenantNavResolutionService.hasAnyTeamManagerPermission()) {
            this.teamManager = true;
        }
    }

    validatePicture() {
        if (
            this.user.pictureMediaId !== null &&
            this.user.pictureMediaId !== undefined &&
            this.user.pictureMediaId !== ""
        ) {
            this.mediaService.getImageData(this.user.pictureMediaId).subscribe(
                (result) => {
                    if (
                        result != null &&
                        result.dataType === "IMAGE" &&
                        result.data != null
                    ) {
                        this.user.pictureUrl = this.sanitizer.bypassSecurityTrustUrl(
                            `data:${result.contentType};base64, ${result.data}`
                        );
                        this.noProfilePict = false;
                    } else {
                        this.noProfilePict = true;
                    }
                },
                (error) => {
                    this.noProfilePict = true;
                    this.ref.detectChanges();
                }
            );
        } else {
            this.noProfilePict = true;
        }

        this.ref.detectChanges();
    }

    confirmLogOut() {
        this.logOutConfirmation.show();
    }

    logOut() {
        this.oauthService.LogOut();
    }

    cancellogOutConfirmationModal() {
        this.logOutConfirmation.hide();
    }

    GetUserViewRoute(): string {
        const url = this.appConfigService.get().tenantConfig?.coreSPA
            ?.serviceUrl;
        return url;
    }

    redirectToUserView(newRoute: string) {
        //BUG 4919 - Redirect correctly to the user view
        window.location.href = this.GetUserViewRoute() + newRoute;
    }
}
