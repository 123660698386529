import { Component, Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn:'root'
})
@Component({
  templateUrl: 'AccessDenied.component.html'
})
export class AccessDeniedComponent {

  constructor() { }

}
