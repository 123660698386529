import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { DefaultLayoutComponent } from "./containers";
import { RoleGuardService } from "src/app/views/roles/roles.guard";
import { UserManagementGuard } from "./views/users/user-management.guard";
import { AccessDeniedComponent } from "./views/error/AccessDenied.component";
import { PageNotFoundComponent } from "./views/page-not-found/page-not-found.component";
import { GroupsGuard } from "./views/groups/groups.guard";
import { ApprovalsModule } from "./views/approvals/approvals.module";
import { ParametricGuardService } from "./views/parametric/parametric.guard";
/**
 * Main app routing. Includes the initial empty redirect.
 * @type {[{path: string; redirectTo: string; pathMatch: string}]}
 */
export const routes: Routes = [
    {
        path: "",
        redirectTo: "admin/roles",
        canActivate: [AuthGuard],
        pathMatch: "full",
    },
    {
        path: "AccessDenied",
        component: AccessDeniedComponent,
        data: {
            title: "Access Denied",
        },
    },
    {
        path: "500",
        component: P500Component,
        data: {
            title: "Page 500",
        },
    },
    {
        path: "logout",
        loadChildren: "./views/logout/logout.module#LogoutModule",
    },
    {
        path: "impersonate",
        loadChildren:
            "./views/impersonate/impersonate.module#ImpersonateModule",
    },
    {
        path: "",
        component: DefaultLayoutComponent,
        data: {
            title: "Home",
        },
        canActivate: [AuthGuard],
        children: [
            {
                path: "admin/users",
                loadChildren: () =>
                    import("./views/users/users.module").then(
                        (m) => m.UsersModule
                    ),
                canActivate: [UserManagementGuard],
            },
            {
                path: "admin/approvals",
                loadChildren: () =>
                    import("./views/approvals/approvals.module").then(
                        (m) => m.ApprovalsModule
                    ),
                canActivate: [UserManagementGuard],
            },
            {
                path: "admin/roles",
                loadChildren: () =>
                    import("./views/roles/roles.module").then(
                        (m) => m.RolesModule
                    ),
                canActivate: [RoleGuardService],
            },
            {
                path: "admin/groups",
                loadChildren: () =>
                    import("./views/groups/groups.module").then(
                        (m) => m.GroupsModule
                    ),
                canActivate: [GroupsGuard],
            },
            {
                path: "admin/parametric",
                loadChildren: () =>
                    import("./views/parametric/parametric.module").then(
                        (m) => m.ParametricModule
                    ),
                canActivate: [ParametricGuardService],
            },
        ],
    },
    {
        path: "**",
        component: P404Component,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
