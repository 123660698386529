<li class="nav-item dropdown ml-1 ml-md-3">
    <a
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        href="#"
        role="button"
    >
        <img
            *ngIf="user?.pictureUrl != null"
            [src]="user?.pictureUrl"
            alt="Avatar"
            class="rounded-circle"
            width="40"
            height="40"
        />
        <img
            *ngIf="!user?.pictureUrl"
            src="../../../../assets/images/user.jpg"
            alt="Avatar"
            class="rounded-circle"
            width="40"
            height="40"
        />
    </a>

    <div class="dropdown-menu dropdown-menu-right pb-0 border-bottom-0">
        <h3 style="text-align: center">
            {{
                "core-common-profile_menu_header"
                    | translate: { defaultText: "Profile" }
            }}
        </h3>
        <a class="dropdown-item" [href]="GetUserViewRoute() + '/profile'">
            <span class="material-icons mr-3">person</span>
            {{ user?.firstName }}
            {{ user?.lastName }}
        </a>
        <a
            class="dropdown-item"
            *ngIf="teamManager"
            [href]="GetUserViewRoute() + '/myTeam'"
        >
            <span class="material-icons mr-3">group</span>
            {{
                "core-common-profile_menu_my_team"
                    | translate: { defaultText: "My Team" }
            }}
        </a>
        <div class="dropdown-item-wrapper pt-2 pb-2">
            <a
                class="dropdown-item"
                *ngFor="let item of userMenuItems"
                [href]="GetUserViewRoute()"
            >
                <div class="switch-menu-img-sm mr-2"></div>
                {{ item.label }}
            </a>

            <a class="dropdown-item" [routerLink]="" (click)="logOut()">
                <span class="material-icons mr-3">lock</span>
                {{
                    "core-common-profile_menu_logout"
                        | translate: { defaultText: "Log Out" }
                }}
            </a>
        </div>
    </div>
</li>
