<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
          <div *ngIf="isLogin" class="clearfix">
              <h1 class="float-left display-3 mr-4"></h1>
              <h6 class="pt-3"></h6>
            </div>
        <div *ngIf="!isLogin" class="clearfix">
          <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">Oops! You're lost.</h4>
          <p class="text-muted">The page you are looking for was not found.</p>
        </div>
      </div>
    </div>
  </div>
</div>
