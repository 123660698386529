import { UsersService } from 'src/app/services/users/users.service';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslatePipe, TranslateService } from "@ngx-translate/core";
/**
 * Main app module. Import your submodules here.
 */
export class BaseAppModule {
  
  setTranslationLocale(translate: TranslateService, usersService: UsersService) {

    const browserLang = usersService.getUserBrowsingLanguage();
    translate.use(browserLang);
    usersService.events().subscribe(message => {
      if (message.event == UsersService.LANGUAGE_CHANGE_EVENT) {
        const browserLang = usersService.getUserBrowsingLanguage();
        translate.use(browserLang);
      }
    });
  }
}
