import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { ModulePermissions } from '../../models/appModules.permissions';


@Injectable()
export class ParametricGuardService implements CanActivate {
  constructor(private permissionsService: PermissionsService, private modulePermissions: ModulePermissions, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const requiredPermissions = [
      this.modulePermissions.VIEW_PARAMETRIC,
      this.modulePermissions.MODIFY_PARAMETRIC
          ];

    if (this.permissionsService.checkUserPermissions(requiredPermissions, false)) {
      return true;
    } else {
      return false;
    }
  }
}
